.picture-card-page {
    padding: 130px 0 50px;

    &__inner {
        max-width: 1230px;
        margin: 0 auto;
        padding: 0 15px; }

    &__prev-step {
        text-align: left;
        margin-bottom: 12px; } }




// Picture Card --------------------
.picture-card {

    &__gallery {
        margin-bottom: 40px;

        &--vertical {
            display: flex;

            .picture-slider {
                margin: 0 15px 0 0;
                width: 100%; }

            .picture-slider__picture {
                height: 420px; }

            .picture-thumbs {
                height: 420px;
                width: 130px; }

            .picture-thumbs__picture {
                width: 130px;
                height: 100%;
                margin: 0; }

            .picture-arrow-prev {
                transform: rotate(90deg);
                top: 12px;
                left: 50%;
                margin: 0 0 0 -12px; }

            .picture-arrow-next {
                transform: rotate(90deg);
                bottom: 12px;
                top: auto;
                right: auto;
                left: 50%;
                margin: 0 0 0 -12px; } } }

    &__descr {
        font-size: 16px;

        h4 {
            text-transform: lowercase;
            font-weight: 700;
            font-size: 16px;
            margin: 0 0 10px; }

        ul {
            padding-left: 20px;
            text-transform: lowercase;
            margin-bottom: 25px; } }


    &__table {
        margin-bottom: 0;
        background-color: transparent;
        table-layout: fixed;

        tbody {
            tr {
                td {
                    vertical-align: middle;
                    padding: 4px 0;
                    font-size: 16px;
                    text-transform: lowercase;

                    &:first-child {
                        width: 10%;
                        padding-right: 15px;
                        font-weight: 700;
                        white-space: nowrap; } } } } } }



.picture-slider {
    margin-bottom: 15px;

    &__link {
        position: relative;
        display: block;

        &::before {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: url("../img/zoom-icon.svg") no-repeat center center;
            opacity: 0;
            visibility: hidden;
            transform: scale(.5);
            transition: all .5s ease;
            content: "";
            z-index: 1; }

        &:hover {

            &::before {
                opacity: 1;
                visibility: visible;
                transform: scale(1); } } }


    &__picture {
        width: 100%;
        height: 280px;
        display: block; }

    &__img {
        width: 100%;
        height: 100%;
        object-fit: cover; } }





.picture-thumbs-wrap {
    position: relative; }


.picture-thumbs {

    &__slide {
        transition: all .5s ease;
        cursor: pointer;

        &.swiper-slide-thumb-active {
            opacity: .5; } }

    &__picture {
        width: 100%;
        height: 110px;
        display: block; }

    &__img {
        width: 100%;
        height: 100%;
        object-fit: cover; } }



.picture-arrow {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border: 1px solid $black;
    background: rgba(255, 255, 255, .6);
    z-index: 10;
    border-radius: 50%;
    cursor: pointer;
    transition: all .5s ease;

    &:hover {
        background: rgba(255, 255, 255, .8); }

    &-prev {
        position: absolute;
        top: 50%;
        left: 12px;
        margin-top: -12px; }

    &-next {
        position: absolute;
        top: 50%;
        right: 12px;
        margin-top: -12px; } }





.picture-info {

    &__title {
        font-size: 16px;
        line-height: 1.2;
        font-weight: 700;
        text-transform: uppercase;
        margin-bottom: 8px; }

    &__descr {
        font-size: 12px;
        color: #888;
        line-height: 1.2;
        margin-bottom: 15px; }

    &__author {
        margin-bottom: 25px;
        max-width: 275px; }

    &__price {
        font-size: 24px;
        line-height: 1.2;
        margin-bottom: 25px; }

    &__buttons {
        display: flex; }

    &__button {
        max-width: 150px;
        width: 100%;
        margin: 0 25px 15px 0;

        &:last-child {
            margin-right: 0; } } }



.progress {
    margin-bottom: 15px;

    &__area {
        position: relative;
        width: 100%;
        height: 4px;
        max-width: 320px;
        background: #e8e8e8;
        margin-bottom: 8px; }

    &__bar {
        position: absolute;
        top: 0;
        left: 0;
        height: 4px;
        background: $black; }

    &__descr {
        font-size: 12px;
        color: #888;
        line-height: 1.2; } }


.painter {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &__picture {
        display: block;
        width: 32px;
        height: 32px;
        min-width: 32px;
        margin-right: 10px;
        border-radius: 50%;
        overflow: hidden; }

    &__img {
        width: 100%;
        height: 100%;
        object-fit: cover; }

    &__title {
        font-size: 18px; } }



.picture-price {
    margin-bottom: 15px;

    &__item {
        display: flex;
        margin-bottom: 15px; }

    &__pic {
        width: 20px;
        min-width: 20px;
        margin-right: 15px; }

    &__title {
        font-weight: 700;
        font-size: 16px;

        span {
            font-weight: 400; } }

    &__chart {

        &-img {
            max-width: 100%;
            height: auto; } } }


// Media -------------------------------------

//Extra small: >0
@include media-breakpoint-down(xs) {
    .picture-card-page {
        padding: 58px 0 50px; }

    .picture-thumbs__picture {
        height: 55px; }

    .picture-card__left {
        margin-bottom: 30px; }

    .picture-price__title {
        font-size: 14px; }

    .picture-info__author {
        max-width: 100%; }

    .picture-info__button {
        max-width: 100%;
        margin-right: 15px; }

    .picture-card__descr {
        font-size: 14px; }

    .picture-card__table tbody tr td {
        font-size: 14px; }

    .picture-card__gallery--vertical {

        .picture-slider__picture {
            height: 320px; }

        .picture-thumbs {
            width: 90px;
            height: 320px; }

        .picture-thumbs__picture {
            width: 90px; } } }
