.start-section {
    position: relative;
    padding: 120px 0;
    overflow: hidden;
    min-height: 100vh;
    display: flex;
    align-items: center;

    &::before {
        position: absolute;
        top: 0;
        right: 0;
        width: 65%;
        height: 100%;
        background-image: url("../../img/main/start-bg.jpg");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        content: ""; }

    &__descr {
        position: relative;
        padding: 55px 80px 55px 0;
        z-index: 0;

        &::before {
            position: absolute;
            top: 0;
            right: 0;
            width: 50vw;
            height: 100%;
            background: #f0f0f0;
            z-index: -1;
            content: ""; } }

    &__title {
        font-family: "Times New Roman", sans-serif;
        font-size: 64px;
        line-height: 1;
        font-weight: 500;
        margin: 0 0 25px; }

    &__text {
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 50px;
        text-transform: lowercase;

        p {
            &:last-child {
                margin-bottom: 0; } } } }



// Media ---------------------------------------------------
@media only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
    .start-section {
        &::before {
            background-image: url("../../img/main/start-bg@2x.jpg"); } } }


@include media-breakpoint-down(lg) {
    .start-section__title {
        font-size: 50px; } }

@include media-breakpoint-down(lg) {
    .start-section__descr {
        padding-right: 20px; }

    .start-section__title {
        font-size: 40px; } }

@include media-breakpoint-down(sm) {
    .start-section {
        min-height: 0;
        padding: 110px 0 50px;
        display: block;

        &::before {
            width: 100%; } }

    .start-section__descr {
        text-align: center;
        padding: 0;

        &::before {
            display: none; } }

    .start-section__title {
        color: #fff; }

    .start-section__text {
        color: #fff;
        margin-bottom: 50px; } }
