.operations-page {
    padding: 130px 0 0;
    display: flex;
    min-height: 100vh;

    &__inner {
        width: 100%;
        max-width: 450px;
        margin: 0 auto;
        padding: 30px 0;

        &--mw-400 {
            max-width: 400px; }

        &--center {
            display: flex;
            align-items: center;
            max-width: 350px; } }

    &__title {
        font-size: 36px;
        margin-bottom: 60px;
        text-transform: uppercase;
        text-align: center; }

    &__descr {
        text-align: center;
        margin: 0 auto 40px;
        font-size: 16px;
        max-width: 350px;

        &--small {
            font-size: 13px;
            margin-bottom: 30px; }

        &--mb-30 {
            margin-bottom: 30px; }

        &--left {
            text-align: left; }

        &--mw-100 {
            max-width: 100%; } }


    &__money {
        text-align: center;
        font-size: 24px;
        line-height: 1;

        span {
            font-size: 13px; } }

    &__form {
        margin-bottom: 40px; }

    &__cancel {
        text-align: center; }

    &__info {
        margin-bottom: 30px; } }


.withdrawal-info {
    margin-bottom: 30px;

    &__header {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 15px; }

    &__icon {
        width: 30px;
        min-width: 30px;
        height: 30px;
        margin-right: 15px; }

    &__title {
        font-size: 16px;
        margin: 0; }

    &__text {
        font-size: 13px;

        p {
            margin-bottom: 0; } } }


.operations-info {
    text-align: center;
    margin-bottom: 20px;

    &__title {
        color: $grey;
        font-size: 13px; }

    &:last-child {
        margin-bottom: 0; } }


.check-card {
    border: 1px solid $black;
    margin-bottom: 40px;

    &__body {
        padding: 20px;
        line-height: 1.2; }

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid $black;
        padding-bottom: 20px;
        margin-bottom: 20px;

        &--border-0 {
            margin-bottom: 0;
            padding-bottom: 0;
            border: 0; } }

    &__price {
        font-size: 16px; }

    &__payment {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;

        &-where {
            font-size: 13px; }

        &-title {
            font-size: 16px; } }

    &__number {
        font-size: 13px;
        text-align: right;
        margin-bottom: 25px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }

    &__info {
        display: flex;
        align-items: center;
        justify-content: space-between;

        &-title {
            display: flex;
            align-items: center;
            font-size: 13px; }

        &-icon {
            width: 18px;
            height: 18px;
            fill: $black;
            margin-left: 5px; }

        &-commission {
            font-size: 16px; }

        &--mb-15 {
            margin-bottom: 15px; } }

    &__total {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #efefef;
        padding: 20px;
        line-height: 1;

        &-title {
            font-size: 13px; }

        &-price {
            font-size: 24px;
            text-align: right;

            span {
                display: block;
                text-align: right;
                line-height: 1.2;
                font-size: 18px; } }

        &-crypto-price {
            font-size: 14px;
            text-align: right;
            padding-top: 10px; } } }

.prev-step {
    text-align: center;
    margin-bottom: 30px;

    &__link {
        display: inline-flex;
        align-items: center;
        text-decoration: none;
        font-size: 12px;
        color: #888;
        line-height: 1;
        text-transform: lowercase;

        &:hover {
            text-decoration: underline; } }

    &__icon {
        width: 6px;
        height: 10px;
        fill: $black;
        margin: 0 12px 0 0; }

    &--left {
        text-align: left; } }



.confirmed {
    text-align: center;

    &__icon {
        margin: 0 auto 40px; }

    &__title {
        font-size: 36px;
        margin-bottom: 20px;
        text-transform: uppercase; }

    &__text {
        font-size: 16px;
        margin-bottom: 30px; }

    &__button {
        max-width: 200px;
        width: 100%; } }



.timer {
    margin-bottom: 30px;

    &__circle {
        width: 70px;
        height: 70px;
        margin: 0 auto 10px;
        border: 2px solid #ccc;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center; }

    &__time {
        font-size: 16px;
        line-height: 1; }

    &__descr {
        font-size: 16px;
        text-align: center;
        color: #999; } }




// Media --------------------------------------------

//Large: ≥992px
@include media-breakpoint-down(lg) {
    .operations-page {
        padding-left: 0; } }



//Extra small: >0
@include media-breakpoint-down(xs) {
    .operations-page {
        padding: 58px 0 60px; }

    .operations-page__inner {
        padding: 20px 15px 30px; }

    .operations-page__title {
        font-size: 18px;
        margin-bottom: 20px; }

    .withdrawal-info__title {
        font-size: 14px; }

    .prev-step {
        margin-bottom: 20px; }

    .operations-page__inner--mw-400 {
        max-width: 100%; }

    .check-card__token .token-item__value {
        font-size: 14px; }

    .check-card__price {
        font-size: 14px; }

    .check-card__body {
        padding: 15px; }

    .check-card__header {
        padding-bottom: 15px; }

    .check-card__payment-title {
        font-size: 14px; }

    .check-card__number {
        font-size: 11px; }

    .check-card__info-commission {
        font-size: 14px; }

    .check-card__total-price {
        font-size: 18px; }

    .check-card__total {
        padding: 15px; }

    .operations-page__descr {
        font-size: 14px;
        margin-bottom: 30px; }

    .operations-page__descr--small {
        font-size: 13px;
        margin-bottom: 20px; }

    .operations-page__form {
        margin-bottom: 20px; }

    .confirmed__icon {
        margin-bottom: 30px;
        width: 120px;
        height: 120px; }

    .confirmed__title {
        font-size: 28px;
        margin-bottom: 15px; }

    .confirmed__text {
        font-size: 14px;
        margin-bottom: 25px; }

    .confirmed__button {
        max-width: 100%; }

    .timer__descr {
        font-size: 14px; }

    .timer {
        margin-bottom: 20px; }

    .operations-page__money {
        font-size: 18px;

        span {
            font-size: 12px; } }

    .check-card--sm-mb-20 {
        margin-bottom: 20px; } }
