.transactions-page {
    padding: 130px 0 60px;

    &__inner {
        max-width: 1230px;
        margin: 0 auto;
        padding: 0 15px; }

    &__title {
        font-size: 36px;
        margin: 0 0 30px;
        text-transform: uppercase; }


    &__filter {
        margin-bottom: 30px; }

    &__table {

        tbody {
            tr {
                td {
                    vertical-align: top; } } }

        &--mobile {
            display: none; } }

    &__call-filter {
        display: none; } }



.progress-ring {

    &__svg {
        position: relative;
        width: 26px;
        height: 26px;
        z-index: 10;
        transform: rotate(-90deg); }

    &__circle {
        width: 100%;
        height: 100%;
        fill: none;
        stroke: rgba(0, 0, 0, .1);
        stroke-width: 2;
        stroke-linecap: round;
        transition: stroke-dashoffset .35s;

        &-fill {
            // stroke-dasharray: 169
            // stroke-dashoffset: calc(169 - (169 * 20) / 100)
            stroke: $black; } } }




.small-timer {
    display: flex;
    align-items: center;
    line-height: 1;

    &__time {
        margin-left: 5px;
        font-size: 16px; } }




// Media ------------------------------------------
@include media-breakpoint-down(lg) {
    .transactions-page {
        padding-left: 0; }

    .transactions-page__inner {
        padding: 30px 40px; } }


@include media-breakpoint-down(sm) {
    .transactions-page__table--mobile {
        display: table; }

    .transactions-page__table--desktop {
        display: none; }

    .hidden-tr__inner {
        display: block; }

    .hidden-tr-info {
        font-size: 12px;
        margin-bottom: 15px;

        &:last-child {
            margin-bottom: 0; } }

    .transactions-page__table tbody tr td {
        padding: 12px 0;
        vertical-align: middle; }

    .hidden-tr__button {
        width: 100%; }

    .transactions-page__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px; }

    .transactions-page__call-filter {
        display: flex; }

    .transactions-page__title {
        margin: 0; }

    .transactions-page__filter {
        display: none; } }


@include media-breakpoint-down(xs) {
    .transactions-page {
        padding: 58px 0 0; }

    .transactions-page__inner {
        padding: 20px 15px 50px; }

    .transactions-page__title {
        font-size: 18px; }

    .transactions-page__call-filter {
        font-size: 12px; }

    .hidden-tr__button {
        font-size: 12px; }

    .small-timer__time {
        font-size: 12px; } }
