.main-faq-block__hidden {
    overflow: hidden;

    &.enter,
    &.exit-done,
    &.exit-active {
        opacity: 0;
        max-height: 0; }

    &.exit,
    &.enter-done,
    &.enter-active {
        opacity: 1;
        max-height: 1000px; }

    &.enter-active {
        transition: max-height .5s cubic-bezier(1, 0, 1, 1), opacity .5s linear; }

    &.exit-active {
        transition: max-height .5s cubic-bezier(0, 1, 0, 1), opacity .5s linear; } }

