.partners-page {
    padding: 130px 0 60px;

    &__inner {
        max-width: 1230px;
        margin: 0 auto;
        padding: 0 15px; }

    &__empty {
        font-size: 18px;
        text-align: center;
        max-width: 650px;
        margin: 135px auto 0;

        p {
            &:last-child {
                margin-bottom: 0; } } } }




.partners-info {
    background: #fafafa;
    padding: 15px;
    margin-bottom: 50px;

    &__col {

        &:first-child {
            border-right: 1px solid $black; } } }



.referral {
    padding: 0 0 0 40px;

    &__title {
        text-transform: lowercase;
        font-weight: 700;
        font-size: 24px;
        line-height: 1.4;
        margin: 0 0 15px; }

    &__form {
        display: flex;
        max-width: 410px;
        margin-bottom: 15px;

        .form__input {
            height: 36px;
            font-size: 12px;
            font-weight: 700; }

        .form__group {
            margin: 0 15px 0 0;
            width: 100%; }

        .form__buttons {
            margin: 0; }

        .form__button {
            width: 125px;
            margin: 0; } }

    &__descr {
        font-size: 12px;

        p {
            margin-bottom: 0; } } }



.bonuses {
    padding: 0 0 0 40px;

    &__title {
        text-transform: lowercase;
        font-weight: 700;
        font-size: 24px;
        line-height: 1.4;
        margin: 0 0 10px; }

    &__token {
        margin-bottom: 12px; }

    &__descr {
        font-size: 12px; } }




// Media ------------------------------------
@include media-breakpoint-down(md) {
    .referral {
        padding: 0; }

    .bonuses {
        padding: 0; }

    .referral__form {
        max-width: 100%; } }



@include media-breakpoint-down(sm) {
    .partners-info__col {

        &:first-child {
            border: 0;
            margin-bottom: 20px; } }

    .partners-info {
        padding: 30px 15px 15px;
        margin-bottom: 20px; }

    .referral {
        text-align: center; }

    .bonuses {
        text-align: center; }

    .bonuses__token {
        justify-content: center; }

    .referral__form {
        .form__button {
            width: auto; } }

    .partners-page__empty {
        font-size: 14px;
        margin: 60px auto 0; } }



@include media-breakpoint-down(xs) {
    .partners-page {
        padding: 58px 0 60px; } }
