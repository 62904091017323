// Fonts
$font-regular: "Helvetica", sans-serif;

// Colors
$black: #000;
$orange: #fe4b01;
$purple: #f9008f;
$grey: #999;
$green: #56ce62;
$pink: #d25bf5;
$red: #e83030;
$yellow: #ffca00;
$blue: #294295;
$light: #fde1c4;

// Customization Bootstrap vars from "app/libs/bootstrap/scss/_variables.scss"
$grid-gutter-width: 30px;
$grid-columns: 12;
$grid-breakpoints: (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1230px);
$container-max-widths: (sm: 540px, md: 720px, lg: 960px, xl: 1230px);

$gutter: $grid-gutter-width;
