.preloader {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
    overflow: visible;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &__img {
        position: relative;
        z-index: 10;
        width: 240px;
        height: auto;
        opacity: 0;
        animation: preloader-logo 2s linear 1 forwards;
        animation-delay: .2s; } }



@keyframes preloader-logo {
    0% {
        opacity: 0; }

    50% {
        opacity: 1; }

    100% {
        opacity: 0; } }
