.gallery-page {
    padding: 130px 0 0;

    &__inner {
        max-width: 1230px;
        margin: 0 auto;
        padding: 0 15px; }

    &__col {
        margin-bottom: 50px; } }





// Gallery Card ----------------------------
.gallery-card {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;

    &__link {
        margin-bottom: 25px;
        display: block;
        text-decoration: none;

        &:hover {
            .gallery-card__img {
                transform: scale(1.1); } } }

    &__picture {
        display: block;
        width: 100%;
        height: 280px;
        overflow: hidden; }

    &__img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform: translate3d(0, 0, 0);
        transition: all 1.5s ease; }

    &__descr {
        text-align: center;
        display: flex;
        flex-direction: column;
        height: 100%;
        width: 100%; }

    &__title {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 16px;
        line-height: 1.2;
        margin: 0 0 8px;
        display: inline-flex;
        justify-content: center;

        &-icon {
            fill: $black;
            min-width: 17px;
            margin-right: 12px; } }

    &__author {
        font-size: 16px;
        line-height: 1.2;
        margin-bottom: 8px; }

    &__available {
        font-size: 12px;
        line-height: 1.2;
        color: #888;
        margin-bottom: 8px; }

    &__price {
        font-size: 16px;
        line-height: 1.2;
        margin-bottom: 15px;

        &--grey {
            color: #808080; } }

    &__button {
        max-width: 150px;
        width: 100%;
        margin: auto auto 0; } }





// Filter Sidebar
.filter-sidebar {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 80;
    transition: all .5s ease;
    padding-bottom: 50px;

    &.is-visible {
        transform: translate3d(0, 0, 0); }

    &__toggle {
        position: absolute;
        top: 130px;
        right: -40px;
        width: 40px;
        height: 40px;
        background: $black;
        display: none;
        align-items: center;
        justify-content: center;

        &-icon {
            fill: #fff; } }


    &__inner {
        position: relative; } }


.filter-form {

    &__search {
        margin-bottom: 30px; }

    &__single {
        margin-bottom: 30px; } }




.filter-dropdown {
    margin-bottom: 20px;

    &__toggle {
        display: flex;
        align-items: center;
        cursor: pointer;

        &-arrow {
            margin-right: 10px;
            fill: $black; }

        &-title {
            font-size: 12px;
            font-weight: 700;
            text-transform: lowercase; }

        &-clear {
            text-decoration: underline;
            font-size: 10px;
            font-weight: 600;
            margin-left: auto;
            text-transform: lowercase;
            cursor: pointer;

            &:hover {
                text-decoration: none; } }

        &.is-active {
            .filter-dropdown__toggle-arrow {
                transform: rotateX(180deg); } } }

    &__body {
        position: relative;
        top: auto;
        left: auto;
        margin: 0;
        display: none;
        border: 0;
        box-shadow: none;
        transition: all .5s ease;

        &.is-visible {
            display: block; } }

    &__items {
        padding: 15px 20px 10px; }

    &__item {
        margin-bottom: 10px;
        font-size: 12px;

        &.is-visible {
            display: block; } }

    &__more {
        text-align: center; }

    &__view-all {
        display: inline-block;
        text-decoration: underline;
        font-size: 10px;
        font-weight: 600;
        margin: 0 auto;
        text-transform: lowercase;
        cursor: pointer;

        &:hover {
            text-decoration: none; } } }


.token-price {
    padding: 20px 30px 0;

    &__val {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
        font-weight: 600;
        line-height: 1.2;
        margin-bottom: 10px; }

    &__line {
        position: relative;
        padding: 8px 0; }

    &__track {
        position: relative;
        width: 100%;
        height: 1px;
        background: $black;

        &::before {
            position: absolute;
            top: 50%;
            left: 0;
            width: 8px;
            height: 8px;
            margin-top: -4px;
            background: $black;
            content: ""; }

        &::after {
            position: absolute;
            top: 50%;
            right: 0;
            width: 8px;
            height: 8px;
            margin-top: -4px;
            background: $black;
            content: ""; } }

    &__handle {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -8px;
        margin-top: -8px;
        width: 16px;
        height: 16px;
        border: 1px solid $black;
        background: #fff;
        cursor: pointer;

        &-val {
            position: absolute;
            top: -25px;
            left: 50%;
            font-size: 12px;
            font-weight: 600;
            line-height: 1.2;
            white-space: nowrap;
            transform: translateX(-50%); } } }



.search {
    position: relative;

    &__input {
        border-radius: 0;
        background: transparent;
        border: 1px solid #ccc;
        font-size: 12px;
        line-height: 1.2;
        color: $black;
        padding: 5px 45px 5px 15px;
        height: 40px;
        font-weight: 400;
        transition: all .5s ease;
        box-shadow: none;
        outline: none;
        appearance: none;
        width: 100%;

        &:hover,
        &:focus {
            border-color: $black; }

        &::-webkit-input-placeholder {
            color: $grey;
            font-weight: 400; }

        &:-moz-placeholder {
            color: $grey;
            font-weight: 400; }

        &::-moz-placeholder {
            color: $grey;
            font-weight: 400; }

        &:-ms-input-placeholder {
            color: $grey;
            font-weight: 400; } }

    &__button {
        position: absolute;
        top: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        text-align: center;
        border: 0;
        padding: 5px;
        height: 40px;
        width: 40px;
        font-size: 12px;
        line-height: 1;
        border-radius: 0;
        background: transparent;
        color: #fff;
        transition: all .5s ease;
        cursor: pointer;
        outline: none;

        &-icon {
            fill: $black;
            transition: all .5s ease; }

        &:hover,
        &:active,
        &:focus:hover {
            outline: none;

            .search__button-icon {
                fill: $orange; } }

        &:focus {
            outline: none;

            .search__button-icon {
                fill: $black; } } } }



// Media ------------------------------------------------

@include media-breakpoint-down(sm) {
    .filter-sidebar {
        position: fixed;
        top: 0;
        left: 0;
        transform: translate3d(-100%, 0, 0);
        max-width: 280px;
        background: #fff;
        padding: 130px 0 0; }

    .filter-sidebar__inner {
        padding: 0 15px 30px;
        height: 100%;
        overflow-y: auto; }

    .filter-sidebar__toggle {
        display: flex;
        opacity: .5; }

    .gallery-page__col {
        margin-bottom: 30px; } }


@include media-breakpoint-down(xs) {
    .filter-sidebar {
        padding-top: 80px; }

    .filter-sidebar__toggle {
        top: 80px; }

    .gallery-card {
        padding-bottom: 30px;
        border-bottom: 1px solid $black; }

    .gallery-page__col:last-child {
        .gallery-card {
            padding-bottom: 0;
            border-bottom: 0; } } }
