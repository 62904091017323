.map-section {
    padding: 100px 0 240px;
    position: relative;
    background: #fafafa;

    &__title {
        text-align: center;
        margin-bottom: 240px; } }




.road-line {
    position: relative;

    &__col {
        &:nth-child(odd) {
            .road-line-item__descr {
                top: calc(100% + 30px); } } }


    &::before {
        position: absolute;
        top: 50%;
        left: 7%;
        width: 86%;
        height: 2px;
        background: #c4c4c4;
        margin-top: -1px;
        content: ""; }

    &-item {
        position: relative;

        &__icon {
            position: relative;
            width: 44px;
            height: 44px;
            min-width: 44px;
            border: 2px solid $black;
            border-radius: 50%;
            background: #fff;
            margin: 0 auto;
            overflow: hidden;

            &::before {
                position: absolute;
                top: 50%;
                left: 50%;
                width: 10px;
                height: 10px;
                background: $black;
                border-radius: 50%;
                margin: -5px 0 0 -5px;
                content: ""; }

            &::after {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: url("../../img/main/check.svg") no-repeat center center;
                content: "";
                display: none; }

            &--checked {
                border-color: #56c644;

                &::before {
                    display: none; }

                &::after {
                    display: block; } } }

        &__descr {
            position: absolute;
            top: -85px;
            left: 50%;
            transform: translate3d(-50%, 0, 0);
            width: 250px;
            text-align: center; }

        &__date {
            font-size: 12px;
            font-weight: 400;
            color: #7d7d7d;
            margin-bottom: 10px; }

        &__title {
            line-height: 1.3;
            font-size: 20px; } } }




// Media ----------------------------------------------
@include media-breakpoint-down(md) {
    .map-section {
        padding: 100px 0; }

    .map-section__title {
        margin-bottom: 100px; }

    .road-line {
        &::before {
            width: 2px;
            height: 88%;
            top: 20px;
            left: 21px; } }

    .road-line-item {
        display: flex;
        align-items: center;
        margin-bottom: 30px; }

    .road-line-item__descr {
        position: relative;
        top: auto;
        left: auto;
        margin: 0;
        transform: none;
        text-align: left;
        width: auto; }

    .road-line-item__icon {
        margin: 0 15px 0 0; } }


@include media-breakpoint-down(sm) {
    .map-section {
        padding: 50px 0 20px; }

    .map-section__title {
        margin-bottom: 50px; }

    .road-line-item__title {
        font-size: 18px; } }
