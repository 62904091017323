html {
    position: relative;
    min-height: 100%; }

body {
    font-size: 16px;
    line-height: 1.6;
    font-family: $font-regular;
    font-weight: 400;
    overflow-x: hidden;
    color: $black;
    background: #fff;
    backface-visibility: hidden; }

a {
    outline: none !important;
    color: $black;
    text-decoration: underline;

    &:hover {
        color: $black;
        text-decoration: none; } }

strong {
    font-family: $font-regular;
    font-weight: 700; }



.button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    border: 0;
    padding: 5px 20px;
    height: 36px;
    width: auto;
    font-size: 12px;
    line-height: 1;
    border-radius: 0;
    background: $black;
    color: #fff;
    transition: all .5s ease;
    cursor: pointer;
    outline: none;
    position: relative;
    text-decoration: none;
    text-transform: lowercase;

    &:hover,
    &:active,
    &:focus:hover {
        outline: none;
        color: #fff;
        background: #454545; }

    &:focus {
        color: #fff;
        background: $black;
        outline: none; }

    &:disabled {
        background: #d0d0d0;
        color: #fff;
        cursor: default;

        .button__icon {
            stroke: #fff; } }

    &__icon {
        width: 16px;
        min-width: 16px;
        height: 16px;
        stroke: #fff;
        margin-right: 8px;
        transition: all .5s ease; }

    &--bordered {
        border: 1px solid $black;
        background: #fff;
        color: $black;

        .button__icon {
            stroke: $black; }

        &:hover,
        &:active,
        &:focus:hover {
            color: $black;
            border-color: $black;
            background: #f2f2f2;

            .button__icon {
                stroke: $black; } }

        &:focus {
            color: $black;
            border-color: $black;
            background: #fff;

            .button__icon {
                stroke: $black; } }

        &:disabled {
            background: #fff;
            color: #d0d0d0;
            cursor: default;
            border-color: #d0d0d0;

            .button__icon {
                stroke: #d0d0d0; } }

        &-red {
            color: $red;
            border-color: $red;

            .button__icon {
                stroke: $red; }

            &:focus {
                color: $red;
                border-color: $red;

                .button__icon {
                    stroke: $red; } } } }

    &--link {
        padding: 5px 20px;
        border: 0;
        background: transparent;
        color: $black;

        &:hover,
        &:active,
        &:focus:hover {
            color: $black;
            background: transparent;
            text-decoration: underline; }

        &:focus {
            color: $black;
            background: transparent; } }

    &--small {
        font-size: 16px;
        height: 40px; }

    &--red {
        background: $red;

        &:hover,
        &:active,
        &:focus:hover {
            color: #fff;
            background: $orange; }

        &:focus {
            color: #fff;
            background: $red; } }

    &--stretch {
        width: 100%;
        display: flex; } }



.button-link {
    display: inline-flex;
    align-items: center;
    font-weight: 700;
    text-align: center;
    user-select: none;
    border: 0;
    padding: 0;
    height: auto;
    width: auto;
    font-size: 12px;
    line-height: 1.6;
    border-radius: 0;
    background: transparent;
    color: $black;
    transition: all .5s ease;
    cursor: pointer;
    outline: none;
    position: relative;
    text-transform: uppercase;

    &:hover,
    &:active,
    &:focus:hover {
        outline: none;
        background: transparent;
        text-decoration: none;
        color: $orange; }

    &:focus {
        background: transparent;
        outline: none;
        color: $black; }

    &__icon {
        width: 16px;
        min-width: 16px;
        height: 16px;
        stroke: $black;
        margin: 3px 0 0 5px;
        transition: all .5s ease; }

    &--red {
        color: $red;
        text-decoration: none;

        .button-link__icon {
            stroke: $red; }

        &:hover,
        &:active,
        &:focus:hover {
            text-decoration: none;
            color: $orange;

            .button-link__icon {
                stroke: $orange; } }

        &:focus {
            text-decoration: none;
            color: $red;

            .button-link__icon {
                stroke: $red; } } }

    &--small {
        font-size: 14px; } }




.main-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    border: 0;
    padding: 5px 15px;
    height: 48px;
    width: auto;
    min-width: 200px;
    font-size: 12px;
    line-height: 1;
    border-radius: 0;
    background: $orange;
    color: #fff;
    transition: all .5s ease;
    cursor: pointer;
    outline: none;
    position: relative;
    text-decoration: none;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .25);

    &:hover,
    &:active,
    &:focus:hover {
        outline: none;
        color: #fff;
        background: #e54300;
        text-decoration: none; }

    &:focus {
        color: #fff;
        background: $orange;
        outline: none; }

    &__icon {
        fill: #fff;
        margin: 3px 0 0 15px;
        transition: all .5s ease; } }


::selection {
    background: $black;
    color: #fff; }

h1 {
    font-size: 36px;
    line-height: 1.2;
    font-weight: 700; }

h2 {
    font-size: 24px;
    line-height: 1.4;
    font-weight: 700; }

h3 {
    font-size: 16px;
    font-weight: 700; }



.logo {
    line-height: 1;

    &__img {
        max-width: 100%;
        height: auto;

        &--mobile {
            display: none; } }

    &__link {
        display: flex;
        align-items: center;
        text-decoration: none;

        &:hover {
            text-decoration: none; } }

    &__version {
        font-size: 12px;
        font-weight: 400;
        color: #888;
        padding: 3px 6px;
        line-height: 1;
        background: #efefef;
        margin-left: 10px; } }



.alert {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    text-align: center;

    &__inner {
        background: $black;
        display: inline-flex;
        align-items: flex-start;
        justify-content: flex-start;
        text-align: left;
        padding: 15px 20px; }

    &__icon {
        width: 16px;
        min-width: 16px;
        height: 16px;
        fill: #56ce62;
        margin-right: 10px;
        margin-top: 3px; }

    &__text {
        color: #fff;
        font-size: 13px;

        p {
            &:last-child {
                margin-bottom: 0; } } }

    &--with-sidebar {
        padding-left: 230px; } }



.cookie {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    text-align: center;

    &__inner {
        display: inline-flex;
        align-items: center;
        justify-content: flex-start;
        text-align: left;
        padding: 15px 30px;
        background: #fff;
        border: 1px solid $black; }

    &__text {
        font-size: 16px;

        p {
            &:last-child {
                margin-bottom: 0; } }

        a {
            color: $black;
            text-decoration: underline;

            &:hover {
                text-decoration: none; } } }

    &__button {
        margin-left: 20px;
        height: 40px;
        padding: 5px 15px; } }



// Table
.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto; }

.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 0;
    background-color: transparent;

    thead {
        tr {
            th {
                font-size: 12px;
                vertical-align: middle;
                padding: 15px 15px 0;
                line-height: 1.2;
                text-transform: lowercase; } } }

    tbody {
        tr {
            td {
                vertical-align: middle;
                border-bottom: 1px solid $black;
                padding: 15px;
                font-size: 16px;
                line-height: 1.4; }

            &.border-none {
                td {
                    border-bottom: 0;
                    padding-bottom: 0; } }

            &.hidden-tr {
                td {
                    background: #fcf9f4;
                    padding: 30px; } } } }

    &-info {
        padding-left: 25px;
        font-size: 13px;
        background: url("../img/table-info-icon.svg") no-repeat top 1px left;

        p {
            &:last-child {
                margin-bottom: 0; } }

        a {
            color: $orange; } }

    &-dropdown {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        cursor: pointer;
        margin: 0 15px 0 auto;

        &.on {
            .table-dropdown__icon {
                transform: rotate(180deg); } }

        &__icon {
            width: 16px;
            height: 9px; } }

    &__buttons {
        display: flex;
        justify-content: flex-end;
        transition: all .5s ease; }

    &__button {
        margin-right: 15px;

        &:last-child {
            margin-right: 0; } }

    &__chart {
        max-width: 140px;

        &-img {
            width: 100%;
            height: auto; } }

    &__info-links {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-wrap: wrap; }

    &--grey {

        thead {
            tr {
                th {
                    font-size: 12px;
                    padding: 15px 20px;
                    border-bottom: 1px solid $black; } } }

        tbody {
            tr {
                td {
                    border-color: #e6e6e6;
                    padding: 20px;
                    transition: all .5s ease; }

                &:hover {
                    td {
                        background: #fafafa; } } } } } }



.support-fixed {
    position: fixed;
    bottom: 25px;
    right: 25px;
    line-height: 1;
    z-index: 130;

    &__toggle {
        cursor: pointer;

        &:hover {
            .support-fixed__icon {
                fill: #454545; } } }

    &__icon {
        width: 38px;
        height: 38px;
        fill: $black;
        transition: all .5s ease; }

    &__dropdown {
        position: absolute;
        bottom: 120%;
        right: 0;
        background: #fafafa;
        box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
        width: 170px;
        padding: 15px 15px 20px;
        opacity: 0;
        visibility: hidden;
        transition: all .5s ease;
        display: flex;
        justify-content: center;

        &.is-visible {
            opacity: 1;
            visibility: visible; } }

    &__list {
        list-style: none;
        padding-left: 0;
        margin: 0; }

    &__item {
        margin-bottom: 10px;
        text-align: right;
        line-height: 1.4;

        &:last-child {
            margin-bottom: 0; } }

    &__link {
        position: relative;
        font-size: 16px;
        color: $black;
        font-weight: 700;
        text-decoration: none;
        text-transform: lowercase;
        transition: all .5s ease;

        &::before {
            position: absolute;
            bottom: -3px;
            left: 50%;
            width: 0;
            height: 2px;
            background: $black;
            transition: all .5s ease;
            content: ""; }

        &:hover {
            color: $black;
            text-decoration: none;

            &::before {
                left: 0;
                width: 100%; } } }

    &--colored {
        .support-fixed__icon {
            fill: #fff; }

        &.is-black {
            .support-fixed__icon {
                fill: $black; }

            &:hover {
                .support-fixed__icon {
                    fill: #454545; } } }

        &:hover {
            .support-fixed__icon {
                fill: #e5e5e5; } } } }


.info-link {
    display: flex;
    align-items: center;
    margin-right: 25px;

    &:last-child {
        margin-right: 0; }

    &__link {
        margin-right: 8px;
        text-transform: lowercase;
        font-size: 14px;
        font-weight: 700; } }



.hidden-tr {
    display: none;

    &.is-hidden {
        display: none; }

    &.is-visible {
        display: table-row; }

    &__inner {
        display: flex;
        align-items: center;
        justify-content: space-between; }

    &-info {
        font-size: 16px;
        display: flex;
        align-items: center;

        &__icon {
            width: 30px;
            height: 30px;
            margin-right: 10px; }

        p {
            margin: 0; } }

    &__button {
        font-size: 12px;
        height: 40px;

        .button__icon {
            width: 20px;
            height: 20px;
            margin-right: 10px; } } }



.small-text {
    font-size: 13px;
    display: block; }

.align-right {
    text-align: right; }

.orange-color {
    color: $orange; }

.red-color {
    color: $red; }

.green-color {
    color: $green; }

.grey-color {
    color: #999; }

.width-25 {
    width: 25%; }



.row--10 {
    margin-left: -10px;
    margin-right: -10px; }

.col--10 {
    padding-left: 10px;
    padding-right: 10px; }


.swiper-wrapper {
    box-sizing: border-box !important; }



// Token Item
.token-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &__picture {
        position: relative;
        width: 46px;
        min-width: 46px;
        height: 46px;
        overflow: hidden;
        line-height: 1; }

    &__img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; }

    &__descr {
        margin-left: 10px; }

    &__value {
        font-size: 24px;
        line-height: 1; }

    &__unit {
        font-size: 13px;
        margin-left: 5px; }

    &__money {
        font-size: 13px; }

    &--middle {

        .token-item__picture {
            width: 30px;
            min-width: 30px;
            height: 30px; }

        .token-item__value {
            font-size: 16px; } }

    &--small {

        .token-item__picture {
            width: 20px;
            min-width: 20px;
            height: 20px; }

        .token-item__descr {
            margin-left: 8px; }

        .token-item__value {
            font-size: 16px; } }

    &--big {
        .token-item__picture {
            width: 60px;
            min-width: 60px;
            height: 60px; }

        .token-item__descr {
            margin-left: 15px; } } }



// Filter -----------
.filter {
    border: 1px solid $black;

    &__form {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        line-height: 1; }

    &__dropdown {

        .dropdown-button {
            padding: 5px 35px 5px 20px;
            border: 0;

            &::after {
                right: 15px; } }

        .dropdown-menu {
            width: 240px; }

        &.is-active {
            .dropdown-button {
                background: #f2f2f2; } } }

    &__buttons {
        margin-left: auto;
        display: flex;
        align-items: center; }

    &__clear {
        margin-right: 15px; } }



// Media ------------------------------------------------
@include media-breakpoint-down(md) {
    .table__button {
        width: 100%; }

    .table tbody tr.hidden-tr td {
        padding: 20px 15px; } }


@include media-breakpoint-down(sm) {
    .support-fixed {
        right: 15px;
        bottom: 10px; } }



@include media-breakpoint-down(xs) {
    .table tbody tr td {
        font-size: 14px; }

    .table-info {
        font-size: 12px; }

    .table__button {
        padding: 5px 10px; }

    .row--sm-m-10 {
        margin-left: -10px;
        margin-right: -10px; }

    .col--sm-p-10 {
        padding-left: 10px;
        padding-right: 10px; }

    .token-item__picture {
        height: 38px;
        width: 38px;
        min-width: 38px; }

    .token-item__value {
        font-size: 18px; }

    .token-item__money,
    .token-item__unit {
        font-size: 12px; }

    .token-item--small .token-item__value {
        font-size: 12px; }

    .alert__inner {
        padding: 15px; }

    .cookie__inner {
        padding: 10px 15px; }

    .cookie__text {
        font-size: 13px; } }
