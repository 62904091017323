.login-page {
    position: relative;
    padding: 40px 0;
    display: flex;
    justify-content: space-between;
    min-height: 100vh;

    &__picture {
        position: relative;
        display: block;
        width: 320px; }

    &__img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover; }

    &__content {
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: 350px;
        margin: 0 30px;
        padding-top: 10px; }

    &__logo {
        margin: 0 auto 90px;

        &--mb-45 {
            margin-bottom: 45px; } }

    &__title {
        margin-bottom: 50px;
        text-align: center;
        text-transform: uppercase; }

    &__descr {
        text-align: center;
        margin-bottom: 40px;
        font-size: 16px;
        line-height: 1.5; }

    &__bottom {
        margin-top: 50px;
        margin-bottom: auto;

        &--mt-30 {
            margin-top: 30px; } }

    &__timer {
        text-align: center;
        font-size: 24px;
        line-height: 1.2; } }


.login-social {

    &__title {
        text-align: center;
        font-size: 16px;
        line-height: 1.2;
        font-weight: 700;
        margin-bottom: 15px; }

    &__list {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 0;
        list-style: none;
        margin: 0; }

    &__item {
        margin-right: 10px;
        width: 100%;

        &:last-child {
            margin: 0; } }

    &__link {
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $black;
        font-size: 12px;
        font-weight: 700;
        line-height: 1;
        text-align: center;
        height: 36px;
        padding: 5px;
        text-decoration: none;
        transition: all .5s ease;

        &:hover {
            color: #34b979; }

        &--google {
            &:hover {
                color: $red; } }

        &--facebook {
            &:hover {
                color: #1876f2; } }

        &--twitter {
            &:hover {
                color: #00acee; } } }

    &--column {
        margin-bottom: 0;

        .login-social__list {
            display: block; }

        .login-social__item {
            margin: 0 0 15px;

            &:last-child {
                margin: 0; } } } }

.login-info {
    text-align: center;
    font-size: 12px;
    font-weight: 700;

    &--left {
        text-align: left; }

    &--small {
        font-size: 13px; } }






// Media ------------------------------------------------

//Extra large: ≥1200px
@include media-breakpoint-down(xl) {
    .login-page__picture {
        width: 620px; } }



@media (max-width: 2019.98px) {
    .login-page__picture {
        width: 320px; } }



//Large: ≥992px
@include media-breakpoint-down(lg) {
    .login-page__picture {
        width: 190px; }

    .login-page__content {
        justify-content: space-between; } }



//Small: ≥576px
@include media-breakpoint-down(sm) {
    .login-page__picture {
        width: 135px; }

    .logo__img--mobile {
        display: inline; }

    .logo__img--desktop {
        display: none; } }



//Extra small: >0
@include media-breakpoint-down(xs) {
    .login-page__logo {
        margin-bottom: 40px; }

    .login-page__picture {
        display: none; }

    .login-page__content {
        margin: 0;
        max-width: 100%;
        padding: 0; }

    .login-page {
        justify-content: center;
        padding: 30px 15px; }

    .login-page__title {
        font-size: 24px;

        &--sm-mb-10 {
            margin-bottom: 10px; }

        &--sm-mb-20 {
            margin-bottom: 20px; } }

    .login-page__descr {
        font-size: 14px; }

    .login-social__title {
        font-size: 13px; }

    .login-social__link {
        font-size: 14px; }

    .login-info {
        font-size: 13px; } }
