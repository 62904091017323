.dashboard-page {
    padding: 80px 0 0 230px;

    &__inner {
        display: flex;
        flex-wrap: wrap; }

    &__left {
        position: relative;
        width: 100%;
        flex: 0 0 50%;
        max-width: 50%;
        padding: 30px 40px; }

    &__right {
        position: relative;
        width: 100%;
        flex: 0 0 50%;
        max-width: 50%; } }



// Profit
.profit {

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 30px; }

    &__title {
        font-size: 24px;
        margin: 0; }

    &__chart {
        margin-bottom: 40px;

        &-img {
            width: 100%;
            height: auto; } } }



.chart-labels {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    &__title {
        font-size: 12px;
        color: #7d7d7d;
        margin-bottom: 5px; }

    &__val {
        font-size: 24px;
        font-weight: 700; }

    &__block {
        line-height: 1.4;

        &--right {
            text-align: right; }

        &--center {
            text-align: center; } } }


// My Case
.my-case {
    margin-bottom: 40px;

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 20px;

        &--mb-10 {
            margin-bottom: 10px; } }

    &__title {
        font-size: 16px;
        margin: 0; }

    &__rate {
        font-size: 24px;
        line-height: 1.2; }

    &__row {
        margin-bottom: 15px;
        align-items: center;

        &:hover {
            cursor: pointer;
            background-color: #f9f9f9; }

        &:last-child {
            margin-bottom: 0; } }

    &__chart {

        &-img {
            width: 100%;
            height: auto; } }

    &__balance {
        font-size: 24px;
        line-height: 1.2;
        margin-bottom: 20px; }

    &__button {
        margin-top: 30px;
        height: 40px;
        font-size: 16px; }

    &-info {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        text-align: left;
        max-width: 385px;

        &__icon {
            width: 16px;
            min-width: 16px;
            height: 16px;
            fill: #56ce62;
            margin-right: 10px;
            margin-top: 2px; }

        &__text {
            font-size: 13px;

            p {
                &:last-child {
                    margin-bottom: 0; } } } } }


// Token Item
.token-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &__picture {
        position: relative;
        width: 46px;
        min-width: 46px;
        height: 46px;
        overflow: hidden;
        line-height: 1; }

    &__img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; }

    &__descr {
        margin-left: 10px; }

    &__value {
        font-size: 24px;
        line-height: 1; }

    &__unit {
        font-size: 13px;
        margin-left: 5px; }

    &__money {
        font-size: 13px; }

    &--middle {

        .token-item__picture {
            width: 30px;
            min-width: 30px;
            height: 30px; }

        .token-item__value {
            font-size: 16px; } }

    &--small {

        .token-item__picture {
            width: 20px;
            min-width: 20px;
            height: 20px; }

        .token-item__descr {
            margin-left: 8px; }

        .token-item__value {
            font-size: 16px; } }

    &--big {
        .token-item__picture {
            width: 60px;
            min-width: 60px;
            height: 60px; }

        .token-item__descr {
            margin-left: 15px; } } }


// Operations
.operations {
    background: #f5ebdb;
    padding: 30px 50px 30px 40px;

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 15px; }

    &__title {
        font-size: 24px;
        margin: 0; }

    &__more {
        text-decoration: none;

        &:hover {
            .operations__more-arrow {
                fill: $red; } }

        &-arrow {
            width: 45px;
            height: 20px;
            fill: $black;
            transition: all .5s ease; } }

    &__table {
        tbody {
            tr {
                td {
                    &:last-child {
                        text-align: right; } } } } }

    &__text {
        text-align: center;
        font-size: 16px;
        color: #ab9572;
        padding: 115px 0;

        p {
            &:last-child {
                margin-bottom: 0; } } } }






// Picture Item
.picture-item {
    position: relative;
    display: block;
    width: 100%;
    text-decoration: none;
    overflow: hidden;

    &:hover {
        .picture-item__picture {
            transform: scale(1.05); } }

    &__picture {
        display: block;
        width: 100%;
        height: 100%;
        transition: all 1.5s ease; }

    &__img {
        width: 100%;
        height: 100%;
        object-fit: cover; }

    &__descr {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(69, 48, 34, .2);
        padding: 30px 50px 30px 40px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        color: #fff; }

    &__date {
        text-align: right;
        font-size: 16px; }

    &__title {
        font-size: 48px;
        line-height: 1.2;
        margin-bottom: 5px; }

    &__author {
        font-size: 16px; }

    &__bottom {
        position: relative;
        padding-right: 65px; }

    &__more {
        position: absolute;
        bottom: 0;
        right: 0;

        &-arrow {
            width: 55px;
            height: 40px;
            fill: #fff; } } }
