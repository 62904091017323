.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: #fff;
    // border-bottom: 1px solid $black
    z-index: 100;

    &__inner {
        padding: 40px 15px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        max-width: 1230px;
        margin: 0 auto;

        &--unregistered {
            padding: 15px 50px; } }

    &__logo {
        margin-right: 20px;

        &--center {
            margin-right: 0;
            width: 100%;
            text-align: center; } }

    &__sign-in {
        margin-left: auto; } }



.menu {
    margin: 0 auto;

    &__list {
        padding-left: 0;
        list-style: none;
        margin: 0;
        display: flex;
        align-items: center; }

    &__item {
        margin-right: 40px;

        &:last-child {
            margin-right: 0; }

        &.is-active {
            .menu__link {
                &::before {
                    width: 100%;
                    left: 0; } } } }

    &__link {
        position: relative;
        display: block;
        text-transform: lowercase;
        font-weight: 700;
        color: $black;
        font-size: 16px;
        text-decoration: none;
        line-height: 1.5;
        white-space: nowrap;

        &::before {
            position: absolute;
            bottom: 0;
            left: 50%;
            width: 0;
            height: 2px;
            background: $black;
            transition: all .5s ease;
            content: ""; }

        &:hover {
            &::before {
                width: 100%;
                left: 0; } } } }


.dark-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, .6);
    opacity: 0;
    visibility: hidden;
    transition: all .5s ease;
    z-index: 80;

    &.is-visible {
        opacity: 1;
        visibility: visible; } }




.header-lang {
    margin-right: 25px;

    &__link {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 700; } }




// User
.header-user {
    position: relative;
    margin: 0;

    &__button {
        height: 32px; }

    &__toggle {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        text-decoration: none;
        line-height: 1;
        cursor: pointer;

        &:hover {
            .header-user__nickname {
                text-decoration: underline; } }

        &.is-active {
            .header-user__arrow {
                transform: rotateX(180deg); } } }

    &__avatar {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 32px;
        height: 32px;
        border-radius: 50%;
        margin-right: 8px;
        overflow: hidden; }

    &__img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: all .5s ease;
        border-radius: 50%; }

    &__nickname {
        font-size: 12px;
        margin-right: 10px; }

    &__arrow {
        fill: $black; }

    &__dropdown {
        position: absolute;
        top: 120%;
        right: 0;
        width: 100%;
        min-width: 150px;
        padding: 15px;
        background: #fafafa;
        box-shadow: 0 4px 4px rgba(0, 0, 0, .25);
        opacity: 0;
        visibility: hidden;
        transition: all .5s ease;
        display: flex;
        justify-content: center;

        &.is-visible {
            opacity: 1;
            visibility: visible; } }

    &__list {
        padding-left: 0;
        list-style: none;
        margin: 0; }

    &__item {
        line-height: 1.4;
        margin-bottom: 10px;

        &:last-child {
            margin-bottom: 0; } }

    &__link {
        position: relative;
        text-transform: lowercase;
        font-weight: 700;
        color: $black;
        font-size: 16px;
        text-decoration: none;

        &::before {
            position: absolute;
            bottom: -3px;
            left: 50%;
            width: 0;
            height: 2px;
            background: $black;
            transition: all .5s ease;
            content: ""; }

        &:hover {
            &::before {
                width: 100%;
                left: 0; } } } }






// Notice
.notice {

    &-toggle {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;
        line-height: 1;

        &:hover {

            .notice-toggle__counter {
                text-decoration: underline; } }

        &__picture {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;
            border: 1px solid $black;
            border-radius: 50%;
            margin-right: 15px;
            overflow: hidden; }

        &__icon {
            width: 22px;
            height: 22px;
            stroke: $black; }

        &__counter {
            font-size: 16px; } }

    &-sidebar {
        position: fixed;
        top: 0;
        right: 0;
        width: 100%;
        max-width: 620px;
        height: 100%;
        background: #fff;
        padding: 80px 0 0;
        z-index: 90;
        visibility: hidden;
        transition: all .5s ease;
        transform: translate3d(100%, 0, 0);

        &.is-visible {
            visibility: visible;
            transform: translate3d(0, 0, 0); }

        &__inner {
            position: relative;
            height: 100%;
            overflow-y: auto;
            // scrollbar-width: thin
            // scrollbar-color: #e6e6e6 #fff

            &::-webkit-scrollbar {
                width: 5px; }

            &::-webkit-scrollbar-track {
                background: #fff; }

            &::-webkit-scrollbar-thumb {
                background-color: #e6e6e6;
                border-radius: 0;
                border: 1px solid #fff; } }

        &__close {
            position: absolute;
            top: 30px;
            right: 20px;
            cursor: pointer;
            line-height: 1;

            &:hover {
                .notice-sidebar__close-icon {
                    stroke: $red; } }

            &-icon {
                width: 24px;
                height: 24px;
                stroke: $black;
                transition: all .5s ease; } } }

    &-block {
        padding: 30px 20px 0;

        &__title {
            font-size: 24px;
            margin-bottom: 15px; }

        &__table {
            table-layout: fixed;

            tbody {
                tr {
                    &:last-child {
                        td {
                            border-bottom: 0; } } } }

            &--mobile {
                display: none; } }

        &--yellow {
            background: #f5ebdb;
            border-bottom: 1px solid $black; } } }




// Media --------------------------------------------------------

//Large: ≥992px
@include media-breakpoint-down(lg) {
    .header__logo {
        margin-right: auto;

        .logo__img--desktop {
            display: none; }

        .logo__img--mobile {
            display: block; } }

    .menu__item {
        margin-right: 30px; } }



//Medium: ≥768px
@include media-breakpoint-down(md) {
    .menu {
        display: none; } }


//Extra small: >0
@include media-breakpoint-down(xs) {
    .header__inner {
        padding: 15px;

        &--unregistered {
            padding: 8px 15px; } }

    .header__sign-in {
        height: 40px;
        font-size: 16px;

        span {
            display: none; } }

    .header__logo .logo__img {
        height: 26px;
        width: auto; }

    .header-lang {
        margin: 0 15px; }

    .header-user__dropdown {
        min-width: 100px; }

    .header-user__link {
        font-size: 12px; }

    .header-user__avatar {
        width: 26px;
        height: 26px; }

    .header-user__nickname {
        display: none; }

    .notice-toggle {
        order: 1;
        margin-right: 15px; }

    .notice-toggle__picture {
        width: 26px;
        height: 26px;
        border: 0;
        margin-right: 0; }

    .notice-toggle__counter {
        display: none; }

    .notice-sidebar {
        padding: 58px 0 0; }

    .notice-block {
        padding: 20px 15px 0; }

    .notice-block__title {
        font-size: 18px;
        margin-bottom: 5px; }

    .notice-sidebar__close {
        top: 25px;
        right: 10px; }

    .notice-block__table--desktop {
        display: none; }

    .notice-block__table--mobile {
        display: table; }

    .header__logo {

        .logo__version {
            display: none; } } }
