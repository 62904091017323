.join-section {
    position: relative;
    background-image: url("../../img/main/join-bg.jpg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 110px 0;

    &__inner {
        background: #fff;
        padding: 80px 30px;
        text-align: center; }

    &__title {
        text-align: center;
        margin-bottom: 35px; }

    &__descr {
        text-align: center;
        font-size: 16px;
        font-weight: 700;
        text-transform: lowercase;
        margin-bottom: 60px;
        line-height: 1.6;

        p {
            &:last-child {
                margin-bottom: 0; } } } }




// Media ---------------------------------------------------
@media only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
    .join-section {
        background-image: url("../../img/main/join-bg@2x.jpg"); } }



@include media-breakpoint-down(sm) {
    .join-section {
        padding: 50px 0; } }
