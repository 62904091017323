.how-section {
    position: relative;
    padding: 80px 0 125px;
    background: #fafafa;
    overflow: hidden;

    &__title {
        margin-bottom: 60px; }

    &__col {
        position: relative;
        z-index: 0; }

    &__descr {
        position: relative;
        padding: 80px 0 110px 115px;
        z-index: 0;

        &::before {
            position: absolute;
            top: 0;
            left: 0;
            width: 50vw;
            height: 100%;
            background: #fff;
            box-shadow: 0 4px 4px 0 rgba(0, 0, 0, .05);
            z-index: -1;
            content: ""; }

        h3 {
            font-size: 24px;
            font-weight: 700;
            line-height: 1.2;
            text-transform: lowercase;
            margin: 0 0 45px; }

        ul {
            padding-left: 0;
            list-style: none;
            margin: 0;

            li {
                position: relative;
                font-size: 16px;
                font-weight: 700;
                padding-left: 14px;
                margin-bottom: 7px;

                &:last-child {
                    margin-bottom: 0; }

                &::before {
                    position: absolute;
                    top: 12px;
                    left: 0;
                    width: 4px;
                    height: 4px;
                    border-radius: 50%;
                    background: #000;
                    content: ""; } } } } }



.how-item {
    display: flex;
    align-items: center;
    margin-bottom: 65px;

    &:last-child {
        margin-bottom: 0; }

    &__pic {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 112px;
        height: 112px;
        min-width: 112px;
        background: #fff;
        border: 1px solid #d4d4d4;
        border-radius: 50%;
        margin: 0 25px 0 50px; }

    &__number {
        position: absolute;
        top: -40px;
        left: -55px;
        font-size: 160px;
        line-height: 1;
        font-weight: 700;
        color: #ebebeb;
        z-index: -1; }

    &__text {
        font-size: 16px;
        line-height: 1.75;
        font-weight: 400;

        p {
            &:last-child {
                margin-bottom: 0; } } } }


// Media ---------------------------------------------------
@include media-breakpoint-down(lg);


@include media-breakpoint-down(md) {
    .how-section {
        padding: 80px 0 40px; }

    .how-section__col {
        margin-bottom: 50px; }

    .how-section__descr::before {
        width: 100%; } }

@include media-breakpoint-down(sm) {
    .how-section {
        padding: 50px 0 20px; }

    .how-section__title {
        margin-bottom: 50px; }

    .how-section__col {
        margin-bottom: 30px; }

    .how-section__descr {
        padding: 30px;

        h3 {
            font-size: 20px;
            margin-bottom: 20px; }

        ul {
            li {
                font-weight: 600; } } }

    .how-item {
        margin-bottom: 30px; }

    .how-item__pic {
        width: 60px;
        height: 60px;
        min-width: 60px;
        margin: 0 15px 0 25px; }

    .how-item__icon {
        width: 30px;
        height: auto; }

    .how-item__text {
        font-weight: 600;

        br {
            display: none; } }

    .how-item__number {
        font-size: 80px;
        top: -15px;
        left: -25px; } }
