.help-page {
    padding: 130px 0 60px;

    &__inner {
        margin: 0 auto;
        max-width: 800px;
        width: 60%;
        padding: 0 15px;

        &--static {
            max-width: 1200px;
            width: 80%; }

        &--mw-500 {
            max-width: 500px; } }

    &__title {
        text-align: center;
        text-transform: uppercase;
        font-size: 36px;
        margin: 0 0 30px;

        &--left {
            text-align: left; } }

    &__descr {
        text-align: center;
        font-size: 13px;
        margin-bottom: 30px; }

    &__links {
        max-width: 400px;
        width: 100%;
        margin: 0 auto; }

    &__form {
        margin-bottom: 20px;

        &--mw-400 {
            max-width: 400px;
            margin: 0 auto; } }

    &__hashtag {
        margin-bottom: 35px; }

    &__search-result {
        text-align: center;
        font-size: 13px;
        margin-bottom: 40px; }

    &__text {
        font-size: 13px; }

    &--mw-500 {
        max-width: 500px; }

    &--unregistered {
        padding-left: 10px; } }





.help-link {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border: 1px solid $black;
    margin-bottom: 10px;
    padding: 20px 40px 20px 20px;
    text-decoration: none;

    &:last-child {
        margin-bottom: 0; }

    &:hover {
        .help-link__arrow {
            transform: translateX(10px); } }

    &__picture {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        min-width: 30px;
        background: $black;
        border-radius: 50%;
        margin-right: 15px;

        &--green {
            background: $green; }

        &--red {
            background: $red; } }

    &__icon {
        width: 22px;
        height: 22px;
        stroke: #fff; }

    &__arrow {
        position: absolute;
        top: 50%;
        right: 25px;
        width: 8px;
        height: 16px;
        margin-top: -8px;
        transition: all .5s ease; } }




.hashtag {

    &__list {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        padding-left: 0;
        list-style: none;
        margin: 0; }

    &__item {
        line-height: 1;
        margin: 0 7px 8px; }

    &__link {
        color: $green;
        text-decoration: underline;
        font-size: 13px;
        transition: all .5s ease;

        &:hover {
            color: $green; }

        &.is-active {
            color: $black;
            text-decoration: none; } } }




// Media -------------------------------------
@include media-breakpoint-down(lg) {
    .help-page {
        padding-left: 0; } }


@include media-breakpoint-down(sm) {
    .help-page__inner {
        width: 100%;
        padding: 20px 15px;
        max-width: 100%; } }


@include media-breakpoint-down(xs) {
    .help-page {
        padding: 58px 0 60px; }

    .help-page__title {
        font-size: 18px;
        margin-bottom: 15px; }

    .help-page__descr {
        font-size: 12px;
        margin-bottom: 20px; }

    .help-link {
        padding: 10px 30px 10px 15px; }

    .help-link__picture {
        margin-right: 10px; }

    .help-link__title {
        font-size: 14px; }

    .help-link__arrow {
        right: 15px; }

    .help-page__prev-step {
        margin-bottom: 15px; }

    .help-page__hashtag {
        margin-bottom: 15px; }

    .help-page__form {
        margin-bottom: 15px; }

    .hashtag__link {
        font-size: 12px; }

    .help-page__search-result {
        font-size: 12px;
        margin-bottom: 20px; }

    .help-page__text {
        font-size: 12px;

        h1 {
            font-size: 20px; }

        h2 {
            font-size: 18px; } }

    .help-page__form--mw-400 {
        max-width: 100%; } }


