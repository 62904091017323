.form {
    position: relative;

    &__row {
        display: flex;
        margin-left: -5px;
        margin-right: -5px; }

    &__col {
        padding-left: 5px;
        padding-right: 5px; }

    &__thead {
        border-bottom: 1px solid $black;
        padding-bottom: 15px;
        margin-bottom: 20px; }

    &__tfoot {
        border-top: 1px solid $black;
        padding-top: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between; }

    &__label {
        display: flex;
        align-items: center;
        margin: 0;
        font-weight: 700;
        font-size: 12px;
        line-height: 1.2;
        text-transform: lowercase;

        &-icon {
            width: 18px;
            height: 18px;
            fill: $black;
            margin-left: 5px; }

        &--right {
            text-align: right;
            justify-content: flex-end; }

        &--center {
            justify-content: center;
            text-align: center; }

        &--fz-16 {
            font-size: 16px; }

        &--fz-24 {
            font-size: 24px; }

        &--mb-10 {
            margin-bottom: 10px; }

        &--mb-20 {
            margin-bottom: 20px; }

        &--grey {
            color: #999;
            font-weight: 400; }

        &--red {
            color: $red; } }

    &__group {
        margin-bottom: 30px;
        position: relative;

        &--mw-350 {
            max-width: 350px;
            margin: 0 auto; }

        &--mb-0 {
            margin-bottom: 0; }

        &--mb-10 {
            margin-bottom: 10px; }

        &--mb-20 {
            margin-bottom: 20px; }

        &.has-error {

            .form__placeholder {
                color: $red; }

            .form__label {
                color: $red; } } }

    &__field-group {
        position: relative; }

    &__show-pass {
        position: absolute;
        top: 5px;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        cursor: pointer;
        transition: all .5s ease;

        svg {
            width: 20px;
            height: 20px; }

        &:hover {
            background: #f3f3f3; } }

    &__copy {
        position: absolute;
        bottom: 5px;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        cursor: pointer;
        background: #fff;
        transition: all .5s ease;

        svg {
            width: 20px;
            height: 20px; }

        &:hover {
            background: #f3f3f3; } }

    &__placeholder {
        position: absolute;
        bottom: 12px;
        left: 0;
        color: $black;
        font-size: 16px;
        line-height: 1.2;
        margin: 0;
        font-weight: 400;
        transition: transform 200ms cubic-bezier(0, 0, .2, 1) 0ms;
        transform: translate(0, 0) scale(1);
        display: block;
        transform-origin: top left;

        &--grey {
            color: #999; }

        &.is-active {
            transform: translate(0, -18px) scale(.8); } }

    &__error {
        position: absolute;
        bottom: 40px;
        left: 0;
        color: $red;
        font-size: 12px;
        line-height: 1.2;
        margin: 0;
        font-weight: 400;
        text-align: center;
        display: none;
        width: 100%;

        &.is-active {
            display: block; } }

    &__input {
        border-radius: 0;
        background: transparent;
        border: 0;
        border-bottom: 1px solid $black;
        font-size: 16px;
        line-height: 1.2;
        color: $black;
        padding: 10px 0;
        height: 40px;
        font-weight: 400;
        transition: all .5s ease;
        box-shadow: none;
        outline: none;
        appearance: none;
        width: 100%;

        &:hover,
        &:focus {
            border-color: $black; }

        &::-webkit-input-placeholder {
            color: $grey;
            font-weight: 400; }

        &:-moz-placeholder {
            color: $grey;
            font-weight: 400; }

        &::-moz-placeholder {
            color: $grey;
            font-weight: 400; }

        &:-ms-input-placeholder {
            color: $grey;
            font-weight: 400; }

        &--center {
            text-align: center; }

        &--right {
            text-align: right; }

        &--clear {
            border: 0;
            padding-right: 30px; }

        &--with-icon {
            padding-right: 30px; }

        &--bordered {
            border: 1px solid $black;
            text-align: center;
            padding: 5px 15px;
            background: #fff; } }

    &__file {
        position: absolute;
        top: 0;
        left: 0;
        outline: 0;
        opacity: 0;
        pointer-events: none;
        user-select: none;
        visibility: hidden; }

    textarea {
        height: auto; }

    &__textarea-length {
        font-size: 13px; }

    &__buttons {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 25px;

        &--center {
            justify-content: center;
            margin-bottom: 0; }

        &--two {
            justify-content: center;
            margin-bottom: 0;

            .button {
                width: auto;
                margin-right: 10px;

                &:last-child {
                    margin-right: 0; } } }

        &--mb-0 {
            margin-bottom: 0; } }

    &__button {
        max-width: 165px;
        width: 100%;

        &--wide {
            max-width: 100%; }

        &--auto {
            max-width: 100%;
            width: auto;
            text-align: left; }

        &--icon {
            max-width: 100%;
            justify-content: flex-start;
            padding: 5px 12px;
            text-align: left;

            svg {
                margin-right: 12px; } } }

    &__link-wrap {
        width: 100%;
        text-align: center; }

    &__link {
        font-size: 12px;
        font-weight: 700;

        &--red {
            color: $red;
            text-decoration: none;
            font-size: 16px;

            &:hover {
                text-decoration: underline;
                color: $red; } }

        &--grey {
            color: #888;
            font-weight: 400;
            text-decoration: none; } }

    &__under-field {
        text-align: right;
        margin-top: 5px; }

    &__social {
        line-height: 1.4;

        &-label {
            font-size: 13px;
            color: $red;
            margin-right: 10px; } }

    &__list {
        font-size: 12px;
        padding-left: 0;
        color: #888;
        list-style: none;
        margin: 0;
        line-height: 1.2;

        &--center {
            text-align: center; } }

    &__info {
        position: relative;
        padding-left: 25px;
        font-size: 13px;
        text-align: left;

        &-icon {
            position: absolute;
            top: 3px;
            left: 0;
            width: 16px;
            height: 16px;
            fill: #56ce62;

            &--red {
                fill: #e83030; }

            &--yellow {
                fill: #e88c30; } }

        &-links {
            a {
                margin-right: 15px; } } }

    &__explain {
        text-align: center;
        font-size: 13px;
        margin: 40px auto 0;
        max-width: 350px;

        &-icon {
            width: 30px;
            height: 30px;
            margin-bottom: 10px; }

        &--bordered {
            padding-bottom: 25px;
            border-bottom: 1px solid #e6e6e6;
            margin-bottom: 40px; } }

    &__descr {
        text-align: center;
        margin: 0 auto 40px;
        font-size: 16px;
        max-width: 350px;

        &--small {
            font-size: 13px;
            margin-bottom: 30px; }

        &--left {
            text-align: left;
            max-width: 100%; } }

    &__icon {
        position: absolute;
        top: 50%;
        right: 5px;
        margin-top: -10px;
        width: 20px;
        height: 20px; }

    &__block {
        margin-bottom: 25px;
        border-bottom: 1px solid #e6e6e6;

        &-title {
            position: relative;
            font-size: 16px;
            margin: 0 0 10px;

            &.is-valid {
                color: #56ce62;

                &::before {
                    position: absolute;
                    top: 5px;
                    left: -22px;
                    width: 16px;
                    height: 16px;
                    background: url("../img/valid-icon.svg") no-repeat center center;
                    content: ""; } }

            &.is-not-valid {
                color: $red;

                &::before {
                    position: absolute;
                    top: 5px;
                    left: -22px;
                    width: 16px;
                    height: 16px;
                    background: url("../img/not-valid-icon.svg") no-repeat center center;
                    content: ""; } } } }

    &__swap {
        position: absolute;
        top: 0;
        left: -50px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 400;
        text-align: center;
        user-select: none;
        border: 0;
        padding: 5px;
        height: 30px;
        width: 30px;
        font-size: 18px;
        line-height: 1;
        border-radius: 50%;
        background: $black;
        color: #fff;
        transition: all .5s ease;
        cursor: pointer;
        outline: none;
        text-decoration: none;

        &:hover,
        &:active,
        &:focus:hover {
            outline: none;
            color: #fff;
            background: $red; }

        &:focus {
            color: #fff;
            background: $black;
            outline: none; }

        &-icon {
            width: 20px;
            min-width: 20px;
            height: 20px;
            stroke: #fff;
            transition: all .5s ease; } }

    &__edit {
        position: absolute;
        right: 0;
        bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 400;
        text-align: center;
        user-select: none;
        border: 0;
        padding: 5px;
        height: 30px;
        width: 30px;
        font-size: 18px;
        line-height: 1;
        border-radius: 0;
        background: transparent;
        color: #fff;
        transition: all .5s ease;
        cursor: pointer;
        outline: none;
        text-decoration: none;

        &:hover,
        &:active,
        &:focus:hover {
            outline: none;
            color: #fff;
            background: transparent;

            .form__edit-icon {
                stroke: #ccc; } }

        &:focus {
            color: #fff;
            background: transparent;
            outline: none;

            .form__edit-icon {
                stroke: $black; } }

        &-icon {
            width: 20px;
            min-width: 20px;
            height: 20px;
            stroke: $black;
            transition: all .5s ease; } }

    &__delete {
        position: absolute;
        top: 8px;
        right: -50px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 400;
        text-align: center;
        user-select: none;
        border: 0;
        padding: 5px;
        height: 30px;
        width: 30px;
        min-width: 30px;
        font-size: 18px;
        line-height: 1;
        border-radius: 50%;
        background: #ccc;
        color: #fff;
        transition: all .5s ease;
        cursor: pointer;
        outline: none;
        text-decoration: none;
        // visibility: hidden

        &:hover,
        &:active,
        &:focus:hover {
            outline: none;
            color: #fff;
            background: $black; }

        &:focus {
            color: #fff;
            background: #ccc;
            outline: none; }

        &-icon {
            stroke: #fff; } } }

// Checkbox
.checkbox {
    padding: 0;
    margin: 0;
    line-height: 1;

    &__input {
        &:not(:checked),
        &:checked {
            position: absolute;
            left: -9999px; }

        &:not(:checked) + .checkbox__label,
        &:checked + .checkbox__label {
            position: relative;
            padding-left: 34px;
            cursor: pointer; }

        &:not(:checked) + .checkbox__label::before,
        &:checked + .checkbox__label::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 16px;
            height: 16px;
            border: 1px solid $black;
            background: transparent;
            transition: all .2s ease; }

        &:checked + .checkbox__label::before {
            background: #fff; }

        &:not(:checked) + .checkbox__label::after,
        &:checked + .checkbox__label::after {
            content: "";
            position: absolute;
            top: 4px;
            left: 4px;
            width: 8px;
            height: 8px;
            background: $black;
            transition: all .2s ease;
            border-radius: 0; }

        &:not(:checked) + .checkbox__label::after {
            opacity: 0;
            transform: scale(0); }

        &:checked + .checkbox__label::after {
            opacity: 1;
            transform: scale(1); }

        &:disabled {
            &:not(:checked) + .checkbox__label::before {
                box-shadow: none;
                border-color: #999; }

            &:checked + .checkbox__label {
                &::before {
                    box-shadow: none;
                    border-color: #999; }

                &::after {
                    background: #999; } }

            + .checkbox__label {
                color: $black; } } }

    &__label {
        font-size: 12px;
        line-height: 1.4;
        font-weight: 400;
        color: $black;
        margin: 0;

        &:hover {
            color: $black; } }

    &--green,
    &--black {
        .checkbox__label {
            font-weight: 700;
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            &-icon {
                fill: $black;
                margin-left: 12px; } } }

    &--green {
        .checkbox__input {
            &:not(:checked) + .checkbox__label::after,
            &:checked + .checkbox__label::after {
                width: 16px;
                height: 16px;
                top: 0;
                left: 0;
                background: url("../img/checkbox-green.svg") no-repeat center center; } } }

    &--mb-15 {
        margin-bottom: 15px; }

    &--mb-30 {
        margin-bottom: 30px; }

    &--big {
        line-height: 1;

        .checkbox__label {
            font-size: 16px;
            line-height: 1; }

        .checkbox__input:not(:checked) + .checkbox__label::before,
        .checkbox__input:checked + .checkbox__label::before {
            top: 50%;
            margin-top: -8px; }

        .checkbox__input:not(:checked) + .checkbox__label::after,
        .checkbox__input:checked + .checkbox__label::after {
            top: 50%;
            margin-top: -4px; } } }



// Quantity
.quantity {
    position: relative;
    display: flex;

    &__price {
        font-size: 20px;
        line-height: 1.2;
        color: $green; }

    &__input {
        border-radius: 0;
        background: transparent;
        border: 0;
        border-bottom: 1px solid $black;
        font-size: 16px;
        line-height: 1.2;
        color: $black;
        padding: 10px 30px;
        height: 40px;
        font-weight: 400;
        transition: all .5s ease;
        box-shadow: none;
        outline: none;
        appearance: none;
        width: 100%;
        text-align: center; }

    &__minus,
    &__plus {
        text-align: center;
        border: 0;
        background: transparent;
        outline: none;
        width: 30px;
        height: 40px;
        padding: 0;
        margin: 0;
        color: $black;
        font-weight: 400;
        font-size: 16px;
        line-height: 1;
        cursor: pointer;
        transition: all .5s ease;

        &:hover,
        &:active,
        &:focus:hover {
            outline: none;
            color: $red; }

        &:focus {
            outline: none;
            color: $black; } }

    &__minus {
        position: absolute;
        top: 0;
        left: 0; }

    &__plus {
        position: absolute;
        top: 0;
        right: 0; }

    &--big {
        .quantity__input {
            border: 0;
            font-size: 24px; } } }




// Padio Button
.radio-button {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: 400;
    user-select: none;
    border: 1px solid $black;
    padding: 15px 20px;
    width: 100%;
    font-size: 16px;
    line-height: 1;
    border-radius: 0;
    background: #fff;
    color: $black;
    transition: all .5s ease;
    cursor: pointer;
    outline: none;
    position: relative;
    text-decoration: none;
    text-align: left;

    &:hover,
    &:active,
    &:focus:hover {
        outline: none;
        border-color: #56ce62;

        .radio-button__picture {
            background: #56ce62; } }

    &:focus {
        outline: none;
        border-color: $black; }

    &__picture {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        min-width: 30px;
        border-radius: 50%;
        background: $black;
        margin-right: 10px;
        transition: all .5s ease; }

    &__img {
        width: 30px;
        height: 30px;
        min-width: 30px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 10px; }

    &__icon {
        width: 22px;
        height: 22px;
        stroke: #fff; }

    &__text {
        line-height: 1.6; }

    &__title {
        font-size: 16px; }

    &__descr {
        font-size: 13px; }

    &--big {
        align-items: flex-start;
        margin-bottom: 10px; }

    &--crypto {
        margin-bottom: 20px; }

    &.is-active {
        border-color: #56ce62;

        .radio-button__picture {
            background: #56ce62; } } }




// Filter Select --------------------------------------------
.filter-select-container.select2-selection--single {
    border-radius: 0;
    background: #fff;
    border: 0;
    font-size: 16px;
    line-height: 1.2;
    color: $black;
    height: 25px;
    font-weight: 400;
    transition: all .5s ease;
    box-shadow: none;
    outline: none;
    vertical-align: middle;
    appearance: none;

    &:hover,
    &:focus {
        border-color: #bfbfbf; } }

.filter-select-container.select2-selection--single .select2-selection__placeholder {
    font-weight: 400;
    color: #707070; }

.filter-select-container.select2-selection--single .select2-selection__rendered {
    height: 25px;
    color: $black;
    line-height: 25px;
    padding-left: 0;
    padding-right: 15px;
    text-align: right; }

.filter-select-container.select2-selection--single .select2-selection__arrow {
    height: 25px;
    width: 10px;

    b {
        border-color: $black transparent transparent;
        border-width: 5px 5px 0; } }

.select2-container--default.select2-container--open .filter-select-container.select2-selection--single .select2-selection__arrow {
    b {
        border-color: transparent transparent $black;
        border-width: 0 5px 5px; } }

.filter-select-dropdown.select2-dropdown {
    border: 0;
    background: #fff;
    color: $black;
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, .15);
    margin-top: 10px;
    z-index: 99999;
    width: auto !important; }

.select2-container--open .filter-select-dropdown.select2-dropdown--below {
    border-radius: 0; }

.filter-select-dropdown .select2-results {
    border-radius: 0;
    overflow: hidden;
    border: 1px solid $black; }

.select2-container--default .filter-select-dropdown .select2-results__option--highlighted[aria-selected] {
    background: #f2f2f2;
    color: $black;
    transition: all .5s ease; }

.select2-container--default .filter-select-dropdown .select2-results__option[aria-selected="true"] {
    background: #f2f2f2;
    color: $black; }

.select2-container--default .filter-select-dropdown {
    font-size: 16px;
    line-height: 1; }

.filter-select-dropdown .select2-results__option {
    padding: 15px; }

@keyframes onAutoFillStart {
    0% {
        background-color: #fff; }

    100% {
        background-color: #fff; } }

@keyframes onAutoFillCancel {
    0% {
        background-color: #fff; }

    100% {
        background-color: #fff; } }


input {
    &:-webkit-autofill {
        animation-name: onAutoFillStart;
        transition: background-color 50000s ease-in-out 0s; }

    &:not(:-webkit-autofill) {
        animation-name: onAutoFillCancel; }

    &:-webkit-autofill::first-line {
        font-size: 16px;
        font-family: NAMU-Pro, sans-serif; } }




// form Select --------------------------------------------
.form-select-container.select2-selection--single {
    border-radius: 0;
    background: #fff;
    border: 0;
    border-bottom: 1px solid $black;
    font-size: 16px;
    line-height: 1.2;
    color: $black;
    height: 40px;
    font-weight: 400;
    transition: all .5s ease;
    box-shadow: none;
    outline: none;
    vertical-align: middle;
    appearance: none;

    &:hover,
    &:focus {
        border-color: $black; } }

.form-select-container.select2-selection--single .select2-selection__placeholder {
    font-weight: 400;
    color: #707070; }

.form-select-container.select2-selection--single .select2-selection__rendered {
    height: 39px;
    color: $black;
    line-height: 1;
    padding-left: 0;
    padding-right: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-start; }

.form-select-container.select2-selection--single .select2-selection__arrow {
    height: 39px;
    width: 10px;

    b {
        border-color: $black transparent transparent;
        border-width: 5px 5px 0; } }

.select2-container--default.select2-container--open .form-select-container.select2-selection--single .select2-selection__arrow {
    b {
        border-color: transparent transparent $black;
        border-width: 0 5px 5px; } }

.form-select-dropdown.select2-dropdown {
    border: 0;
    background: #fff;
    color: $black;
    box-shadow: 0 15px 30px 0 rgba(0, 0, 0, .15);
    margin-top: 10px;
    z-index: 99999;
 }    // width: auto !important

.select2-container--open .form-select-dropdown.select2-dropdown--below {
    border-radius: 0; }

.form-select-dropdown .select2-results {
    border-radius: 0;
    overflow: hidden;
    border: 1px solid $black; }

.select2-container--default .form-select-dropdown .select2-results__option--highlighted[aria-selected] {
    background: #f2f2f2;
    color: $black;
    transition: all .5s ease; }

.select2-container--default .form-select-dropdown .select2-results__option[aria-selected="true"] {
    background: #f2f2f2;
    color: $black; }

.select2-container--default .form-select-dropdown {
    font-size: 16px;
    line-height: 1; }

.form-select-dropdown .select2-results__option {
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: flex-start; }

.select-token {
    position: relative;
    width: 20px;
    min-width: 20px;
    height: 20px;
    border-radius: 50%;
    overflow: hidden;
    line-height: 1;
    margin-right: 8px;

    &__img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; } }



// Progress Bar -----------------------------
.progress {

    &-bar {
        position: relative;
        height: 30px;
        width: 100%;
        display: flex;
        border: 1px solid $black;
        overflow: hidden;
        margin-bottom: 15px;

        &__sold {
            height: 100%;
            background: url("../img/progress-bar-icon.svg") no-repeat center center, url("../img/progress-bar-lines.svg") repeat-x top left; }

        &__profit {
            background: $green;
            border-right: 1px solid $black; } }

    &-info {
        margin-bottom: 30px;

        &__table {
            width: 100%;
            max-width: 100%;
            margin-bottom: 0;
            background-color: transparent;
            table-layout: fixed;

            tbody {
                tr {
                    td {
                        vertical-align: bottom;
                        line-height: 1;
                        padding: 6px 0; } } }

            &--auto {
                table-layout: auto; } }

        &__icon {
            display: inline-block;
            width: 10px;
            height: 10px;
            border: 1px solid $black;
            margin-right: 3px;

            &--green {
                background: $green; }

            &--striped {
                background: url("../img/progress-bar-lines.svg") repeat-x top left;
                background-size: 50px; } }

        &__title {
            font-size: 13px;
            display: inline;
            line-height: 1; }

        &__val {
            font-size: 16px;
            line-height: 1; } } }




// Upload Avatar -----------
.upload-avatar {
    position: relative;
    max-width: 180px;
    width: 100%;

    &__avatar {
        position: relative;
        margin-bottom: 15px; }

    &__default {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 180px;
        height: 180px;
        border: 1px dashed #d2d2d2;

        &-circle {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;
            background: $black;
            border-radius: 50%; }

        &-icon {
            width: 22px;
            height: 22px;
            stroke: #fff; } }

    &__picture {
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1;
        width: 150px;
        height: 150px;
        min-width: 150px;
        border-radius: 50%;
        overflow: hidden; }

    &__photo {
        filter: grayscale(100%);
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all .5s ease;
        border-radius: 50%;

        &:hover {
            filter: grayscale(0); } }

    &__delete {
        position: absolute;
        top: 5px;
        right: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 400;
        text-align: center;
        vertical-align: middle;
        user-select: none;
        border: 1px solid #fff;
        padding: 5px;
        height: 30px;
        width: 30px;
        min-width: 30px;
        font-size: 18px;
        line-height: 1;
        border-radius: 50%;
        background: $black;
        color: #fff;
        transition: all .5s ease;
        cursor: pointer;
        outline: none;
        text-decoration: none;

        &:hover,
        &:active,
        &:focus:hover {
            outline: none;
            color: #fff;
            background: $red; }

        &:focus {
            color: #fff;
            background: $black;
            outline: none; }

        &-icon {
            stroke: #fff; } }

    &__button {
        width: 100%;
        margin-top: 20px; }

    &__descr {
        font-size: 12px;
        color: #999;
        text-align: center;
        line-height: 1.2;

        p {
            margin-bottom: 0; } } }




// Upload File
.upload-file {

    &-place {
        border: 1px dashed #ccc;
        padding: 30px 20px;

        &__circle {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
            height: 30px;
            background: $black;
            border-radius: 50%;
            margin: 0 auto 10px; }

        &__icon {
            width: 22px;
            height: 22px;
            stroke: #fff; }

        &__descr {
            text-align: center;
            font-size: 13px; } }

    &-attach {
        display: flex;
        align-items: center;

        &__picture {
            width: 100px;
            min-width: 100px;
            height: 55px;
            margin-right: 20px; }

        &__img {
            width: 100%;
            height: 100%;
            object-fit: cover; }

        &__title {
            font-size: 13px;
            margin-bottom: 5px; }

        &__buttons {
            .button-link {
                margin-right: 10px;

                &:last-child {
                    margin-right: 0; } } } } }



.connect-social {
    max-width: 270px;

    &__item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;

        &:last-child {
            margin-bottom: 0; }

        &.is-active {
            .connect-social__delete {
                visibility: visible; } } }

    &__button {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-weight: 400;
        text-align: left;
        user-select: none;
        border: 1px solid $black;
        padding: 5px 15px;
        height: 40px;
        width: 100%;
        font-size: 16px;
        line-height: 1;
        border-radius: 0;
        background: #fff;
        color: $black;
        transition: all .5s ease;
        cursor: pointer;
        outline: none;
        position: relative;
        text-decoration: none;
        margin-right: 10px;

        &:hover,
        &:active,
        &:focus:hover {
            outline: none;
            color: #fff;
            background: $black;

            .connect-social__button-icon {
                fill: #fff; } }

        &:focus {
            color: $black;
            background: #fff;
            outline: none; }

        &:disabled {
            color: $black;
            background: #fff;
            cursor: default; }

        &-pic {
            position: relative;
            width: 16px;
            height: 16px;
            min-width: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 15px;
            line-height: 1; }

        &-icon {
            fill: $black;
            transition: all .5s ease; } }

    &__delete {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 400;
        text-align: center;
        vertical-align: middle;
        user-select: none;
        border: 0;
        padding: 5px;
        height: 30px;
        width: 30px;
        min-width: 30px;
        font-size: 18px;
        line-height: 1;
        border-radius: 50%;
        background: #ccc;
        color: #fff;
        transition: all .5s ease;
        cursor: pointer;
        outline: none;
        position: relative;
        text-decoration: none;
        visibility: hidden;

        &:hover,
        &:active,
        &:focus:hover {
            outline: none;
            color: #fff;
            background: $black; }

        &:focus {
            color: #fff;
            background: #ccc;
            outline: none; }

        &-icon {
            stroke: #fff; } } }



// QR Code Copy Form -----------------------
.qr-copy-form {
    display: flex;
    max-width: 410px;
    margin-bottom: 20px;

    .form__input {
        height: 36px;
        font-size: 12px;
        font-weight: 700; }

    .form__group {
        margin: 0 15px 0 0;
        width: 100%; }

    .form__buttons {
        margin: 0; }

    .form__button {
        width: 125px;
        margin: 0; }

    &__qr-img {
        max-width: 100%;
        height: auto; } }



// Dropdown ------------------------------------
.dropdown {
    position: relative;

    &-button {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        font-weight: 400;
        text-align: left;
        user-select: none;
        border: 0;
        border-bottom: 1px solid $black;
        padding: 5px 15px 5px 0;
        height: 40px;
        width: 100%;
        font-size: 16px;
        line-height: 1;
        border-radius: 0;
        background: #fff;
        color: $black;
        transition: all .5s ease;
        cursor: pointer;
        outline: none;
        position: relative;
        text-decoration: none;
        appearance: none;

        span {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            line-height: 32px; }

        &:hover,
        &:active,
        &:focus:hover {
            outline: none;
            background: #fff; }

        &:focus {
            background: #fff;
            outline: none; }

        &::after {
            position: absolute;
            top: 50%;
            right: 0;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 5px 5px 0;
            border-color: $black transparent transparent;
            content: ""; }

        &--right {
            justify-content: flex-end; }

        &--border-0 {
            border: 0; }

        &--green {
            color: $green; } }

    &-menu {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        border: 1px solid $black;
        background: #fff;
        box-shadow: 0 15px 30px 0 rgba(0, 0, 0, .15);
        margin-top: 10px;
        z-index: 999;
        visibility: hidden;
        opacity: 0;
        max-height: 200px;
        overflow-y: auto;

        &.is-visible {
            visibility: visible;
            opacity: 1; }

        &__list {
            padding-left: 0;
            list-style: none;
            margin: 0; }

        &__item {
            padding: 15px;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-size: 16px;
            line-height: 1;
            color: $black;
            cursor: pointer;
            transition: all .5s ease;

            &:hover {
                background: #f2f2f2; }

            &.is-disabled {
                background: #f9f9f9;
                color: #777;
                filter: grayscale(100%);
                cursor: not-allowed; } } }

    &.is-active {

        .dropdown-button {

            &::after {
                transform: rotate(180deg); } } } }





// Media ------------------------------------------------
@include media-breakpoint-down(sm) {
    .qr-copy-form {
        .form__button {
            width: auto; } } }



//Extra small: >0
@include media-breakpoint-down(xs) {
    .form__buttons {
        flex-direction: column;
        margin-bottom: 15px; }

    .form__button {
        max-width: 100%;
        margin-bottom: 15px;

        &--auto {
            width: 100%; } }

    .form__social {
        text-align: center;

        &-label {
            display: block;
            margin: 0; } }

    .form__placeholder {
        font-size: 14px; }

    .form__input {
        font-size: 14px; }

    .form__link-wrap {
        margin-bottom: 10px; }

    .form-select-container.select2-selection--single {
        font-size: 12px; }

    .quantity__input {
        font-size: 12px; }

    .form__input--clear {
        font-size: 12px;
        border-bottom: 1px solid $black; }

    .form__thead {
        margin-bottom: 5px; }

    .form__explain {
        margin-top: 10px; }

    .form-select-dropdown .select2-results__option {
        padding: 5px;
        font-size: 12px; }

    .form__label--fz-16 {
        font-size: 14px; }

    .form__label--mb-20 {
        margin-bottom: 15px; }

    .radio-button__picture {
        width: 26px;
        height: 26px;
        min-width: 26px; }

    .radio-button__icon {
        width: 18px;
        height: 18px; }

    .radio-button--crypto {
        padding: 10px;
        margin-bottom: 15px;

        .radio-button__title {
            font-size: 14px; }

        .radio-button__img {
            width: 24px;
            height: 24px;
            min-width: 24px;
            margin-right: 5px; } }

    .form__button--icon {
        margin-bottom: 0; }

    .form__descr {
        font-size: 14px; }

    .form__explain--bordered {
        padding-bottom: 20px;
        margin-bottom: 30px; }

    .form__block {
        margin-bottom: 20px; }

    .form__block-title {
        font-size: 14px; }

    .form__block-title.is-not-valid {
        &::before {
            display: none; } }

    .form__block-title.is-valid {
        &::before {
            display: none; } }

    .form__swap {
        position: relative;
        top: auto;
        left: auto;
        margin: -45px 0 15px auto; }

    .form__group--with-delete {
        padding-right: 30px; }

    .form__delete {
        width: 20px;
        height: 20px;
        min-width: 20px;
        right: 0;
        top: 10px; }

    .upload-file-attach__buttons {
        .button-link {
            font-size: 14px; } }

    .upload-file-attach__title {
        font-size: 12px; }

    .upload-avatar__default {
        width: 100px;
        height: 100px;
        min-width: 100px;
        margin: 0 auto; }

    .upload-avatar__picture {
        width: 100px;
        height: 100px;
        min-width: 100px;
        margin: 0 auto; }

    .upload-avatar__photo {
        width: 100px;
        height: 100px; }

    .upload-avatar__left {
        margin-right: 20px; }

    .upload-avatar__right {
        width: auto;
        min-width: 0; }

    .upload-avatar {
        max-width: 100%; }

    .upload-avatar__delete {
        right: 50%;
        margin-right: -65px; }

    .upload-avatar__descr {
        font-size: 12px;
        line-height: 1.2; }

    .form__buttons--two {
        flex-direction: row;

        .button {
            font-size: 14px;
            padding: 5px 15px; } }

    .checkbox--mb-15 {
        margin-bottom: 10px; }

    .dropdown-menu__item {
        padding: 5px;
        font-size: 12px; }

    .dropdown-button {
        font-size: 14px; }

    .connect-social {
        max-width: 100%; } }
