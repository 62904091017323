.error-page {
    padding: 80px 0 0 230px;

    &__inner {
        padding: 0 30px 60px;
        margin: 0 auto;
        max-width: 1200px;
        width: 100%;

        &--pt-120 {
            padding-top: 120px; }

        &--pt-50 {
            padding-top: 50px; } }

    &__title {
        margin: 0 0 30px; }

    &__descr {
        p {
            font-size: 24px;
            line-height: 1.4;
            margin-bottom: 1.8rem; }

        &--mt-120 {
            margin-top: 120px; } }

    &__number {
        font-size: 250px;
        line-height: 1;
        margin: -30px 0 30px; }

    &__button {
        width: 100%;
        max-width: 200px; }

    &__picture {
        display: block;
        text-align: center; }

    &__img {
        max-width: 100%;
        height: auto; }

    &--update {
        padding-left: 0; } }



// Media ----------------------------------------
@include media-breakpoint-down(lg) {
    .error-page {
        padding-left: 0; }

    .error-page__inner {
        padding-left: 40px; } }


@include media-breakpoint-down(md) {
    .error-page__number {
        font-size: 150px;
        margin-top: 0; } }



@include media-breakpoint-down(sm) {
    .error-page__picture {
        display: none; }

    .error-page__descr--mt-120 {
        margin-top: 0; }

    .error-page__descr {
        text-align: center; }

    .error-page__inner {
        padding: 40px 15px; } }


@include media-breakpoint-down(xs) {
    .error-page__descr p {
        font-size: 18px; }

    .error-page__number {
        font-size: 100px; }

    .error-page__button {
        max-width: 100%; } }
