@import "vars";
@import "bootstrap/scss/mixins/breakpoints";


.swiper-container {
    -webkit-transform: translate3d(0, 0, 0); }

// ==========  Desktop First  ==========

//Large: ≥992px
@include media-breakpoint-down(lg) {

    // Dashboard --------------
    .dashboard-page {
        padding-left: 0; }

    .operations {
        padding: 30px 40px; } }


//Medium: ≥768px
@include media-breakpoint-down(md) {

    // Dashboard --------------
    .dashboard-page__left,
    .dashboard-page__right {
        max-width: 100%;
        flex: 0 0 100%; } }


//Small: ≥576px
@include media-breakpoint-down(sm) {

    // Dashboard --------------
    .notice-sidebar {
        max-width: 100%; }

    .notice-block {
        padding: 30px 40px 0; } }


//Extra small: >0
@include media-breakpoint-down(xs) {

    // Dashboard --------------
    .profit__title {
        font-size: 18px; }

    .filter-select-container.select2-selection--single {
        font-size: 12px; }

    .sidebar__inner {
        padding: 85px 15px 20px; }

    .main-menu__item,
    .bottom-menu__item {
        margin-bottom: 15px;
        line-height: 1.4; }

    .main-menu__link,
    .bottom-menu__link {
        font-size: 14px; }

    .dashboard-page {
        padding-top: 60px; }

    .dashboard-page__left {
        padding: 20px 15px 0; }

    .profit__header {
        margin-bottom: 20px; }

    .my-case__header {
        margin-bottom: 15px; }

    .my-case__title {
        font-size: 14px; }

    .my-case {
        margin-bottom: 35px; }

    .my-case__balance {
        font-size: 18px; }

    .my-case-info__text {
        font-size: 12px; }

    .my-case__button {
        width: 100%; }

    .my-case__rate {
        font-size: 18px;
        text-align: right; }

    .operations {
        padding: 20px 15px; }

    .operations__title {
        font-size: 18px; }

    .operations__more-arrow {
        width: 35px;
        height: 15px; }

    .notice-block__table--small tbody tr td {
        font-size: 12px; }

    .small-text {
        font-size: 12px; }

    .picture-item__descr {
        padding: 15px; }

    .picture-item__date {
        font-size: 14px; }

    .picture-item__title {
        font-size: 24px; }

    .picture-item__author {
        font-size: 12px; }

    .picture-item__more-arrow {
        width: 44px;
        height: 32px; }

    .operations__text {
        padding: 30px 0;

        br {
            display: none; } }

    .chart-labels {

        &__title {
            font-size: 12px; }

        &__val {
            font-size: 18px; } }

    .picture-item__bottom {
        padding-right: 50px; } }


// ==========  Mobile First  ==========

//Extra small: <576px
@include media-breakpoint-up(xs);


//Small: ≤576px
@include media-breakpoint-up(sm);


//Medium: ≤768px
@include media-breakpoint-up(md);


//Large: ≤992px
@include media-breakpoint-up(lg);


//Extra large: ≤1200px
@include media-breakpoint-up(xl);
