.faq-page {
    padding: 130px 0;

    &__inner {
        max-width: 830px;
        margin: 0 auto;
        padding: 0 15px; }

    &__title {
        font-size: 36px;
        margin-bottom: 60px;
        text-transform: uppercase;
        text-align: center; } }



.main-faq-block {
    overflow: hidden;
    margin-bottom: 40px;
    padding-bottom: 40px;
    border-bottom: 1px solid #d0d0d0;

    &:last-child {
        margin-bottom: 0;
        border: 0; }

    &__toggle {
        position: relative;
        padding: 0 70px 0 0;
        cursor: pointer;
        transition: all .5s ease;

        &:hover {
            .main-faq-block__title {
                color: $orange; } } }

    &__title {
        font-size: 24px;
        font-weight: 700;
        margin: 0;
        text-transform: lowercase;
        line-height: 1.6;
        transition: all .5s ease; }

    &__icon {
        position: absolute;
        top: 0;
        right: 0;
        width: 40px;
        height: 40px;
        background: $black;
        border: 1px solid $black;
        border-radius: 50%;
        transition: all .5s ease;

        &::before {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 20px;
            height: 2px;
            margin: -1px 0 0 -10px;
            background: #fff;
            content: ""; }

        &::after {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 2px;
            height: 20px;
            margin: -10px 0 0 -1px;
            background: #fff;
            transition: all .5s ease;
            content: ""; } }

    &__hidden {

        &.hidden {
            display: none; }

        &.visible {
            display: block; } }


    &__descr {
        padding: 15px 105px 0 0;
        font-size: 18px;
        line-height: 1.75;

        p {
            margin-bottom: 0; }

        a {
            color: $orange;
            text-decoration: none;

            &:hover {
                text-decoration: underline; } } }

    &.on {
        .main-faq-block__toggle {
            &:hover {
                .main-faq-block__title {
                    color: $orange; } } }

        .main-faq-block__title {
            color: $black; }

        .main-faq-block__icon {
            background: transparent;

            &::after {
                opacity: 0; }

            &::before {
                background: $black; } } } }




// Media ----------------------------------------------
@include media-breakpoint-down(sm) {
    .main-faq-section__title {
        margin-bottom: 50px; }

    .main-faq-block__title {
        font-size: 18px; }

    .main-faq-block__descr {
        padding-right: 0;
        font-size: 16px; }

    .main-faq-block__toggle {
        padding-right: 50px; } }

@include media-breakpoint-down(xs) {
    .faq-page {
        padding: 58px 0; }

    .faq-page__inner {
        padding: 20px 15px 30px; }

    .faq-page__title {
        font-size: 18px;
        margin-bottom: 20px; } }
