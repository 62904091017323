.settings-page {
    padding: 130px 0 60px;

    &__inner {
        max-width: 1230px;
        margin: 0 auto;
        padding: 0 15px; }

    &__title {
        font-size: 24px;
        margin: 0 0 30px;

        &--center {
            text-align: center; } }

    &__descr {
        text-align: center;
        font-size: 16px;
        margin-bottom: 40px; }

    &__tabs {
        width: 100%; }

    &__tabs-toggle {
        margin-bottom: 30px; }

    &__center {
        max-width: 350px;
        margin: 0 auto; } }


// .account-settings
//     width: 100%
//     display: flex
//     align-items: center
//     justify-content: space-between
//     margin-top: auto

//     &__link
//         font-size: 16px

//         &--grey
//             color: #999

//             &:hover
//                 color: #999


// Media ---------------------------------------
@include media-breakpoint-down(md);



@include media-breakpoint-down(xs) {
    .settings-page {
        padding: 58px 0 60px; }

    .settings-page__inner {
        padding: 20px 15px 0; }

    .settings-page__title {
        font-size: 18px;
        margin-bottom: 15px; }

    .settings-page__tabs-toggle {
        overflow-x: auto;

        .tabs-toggle__item {
            white-space: nowrap; } }

    .settings-page__descr {
        font-size: 14px;
        margin-bottom: 25px; } }
