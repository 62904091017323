.tabs {

    &__pane {
        opacity: 0;
        transition: all .5s ease;
        display: none;

        &.on {
            display: block; }

        &.visible {
            opacity: 1; } }

    &-toggle {
        position: relative;
        padding-left: 0;
        list-style: none;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        z-index: 10;

        &__item {
            cursor: pointer;
            transition: all .5s ease;
            line-height: 1;
            font-size: 12px;
            font-weight: 700;
            color: $black;
            position: relative;
            padding: 8px 15px;
            text-align: center;
            border: 1px solid $black;
            margin-right: 8px;
            text-transform: lowercase;

            &:last-child {
                margin-right: 0; }

            &:hover {
                background: rgba(0, 0, 0, .05); }

            &.on {
                color: #fff;
                background: $black; } } } }


