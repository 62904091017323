.faq-block {
    overflow: hidden;
    margin-bottom: 10px;
    border: 1px solid $black;

    &__toggle {
        position: relative;
        padding: 15px 50px 20px 20px;
        cursor: pointer;
        transition: all .5s ease; }

    &__hashtag {
        color: $green;
        font-size: 13px;
        margin-bottom: 5px; }

    &__title {
        font-size: 16px;
        margin: 0; }

    &__arrow {
        position: absolute;
        top: 50px;
        right: 22px;
        width: 16px;
        height: 8px;
        fill: $black; }

    &__hidden {

        &.hidden {
            display: none; }

        &.visible {
            display: block; } }


    &__descr {
        padding: 0 20px 20px;
        font-size: 13px;
        line-height: 1.6;

        p {
            margin-bottom: 0; } }

    &.on {

        .faq-block__arrow {
            fill: $red;
            transform: rotate(180deg); } } }




// Media -------------------------------------
@include media-breakpoint-down(xs) {
    .faq-block__toggle {
        padding: 10px 40px 10px 15px; }

    .faq-block__title {
        font-size: 14px; }

    .faq-block__hashtag {
        font-size: 12px; }

    .faq-block__arrow {
        top: 42px;
        right: 10px; }

    .faq-block__descr {
        font-size: 12px;
        padding: 0 15px 15px; } }
