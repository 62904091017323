.wallet-section {
    padding: 100px 0;
    overflow: hidden; }



.main-work-slider {

    &__title {
        font-size: 36px;
        margin: 0 0 35px;
        font-weight: 700; }

    &__text {
        font-size: 18px;
        line-height: 1.75;
        max-width: 490px;

        p {
            &:last-child {
                margin-bottom: 0; } } }

    &-nav {
        width: 115px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 70px;

        &__prev,
        &__next {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background: transparent;
            border: 1px solid $black;
            cursor: pointer;
            transition: all .5s ease;
            outline: none;

            &:hover {
                background: $black;
                outline: none;

                .main-work-slider-nav__icon {
                    stroke: #fff; } } }

        &__icon {
            stroke: $black;
            width: 24px;
            height: 24px;
            transition: all .5s ease; } } }


.main-image-slider {
    width: calc(795px + 10px);
    padding: 0 5px;
    margin-left: -5px;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .15);
    margin-bottom: 10px;

    &__slide {
        margin: 10px 0; }

    &__picture {
        display: block;
        width: 100%;
        height: 100%; }

    &__img {
        width: 100%;
        height: 100%;
        object-fit: cover; } }





// Media ----------------------------------------------
@include media-breakpoint-down(md) {
    .main-image-slider {
        width: auto;
        margin: 0 0 50px; } }

@include media-breakpoint-down(sm) {
    .wallet-section {
        padding: 50px 0; }

    .main-work-slider__slide {
        text-align: center; }

    .main-work-slider__title {
        font-size: 30px;
        font-weight: 700; }

    .main-work-slider__text {
        font-size: 16px; }

    .main-work-slider-nav {
        margin: 50px auto 0; } }
