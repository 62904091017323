.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 230px;
    height: 100%;
    border-right: 1px solid $black;
    background: #fff;
    visibility: hidden;
    transform: translate3d(-100%, 0, 0);
    z-index: 90;
    transition: all .5s ease;

    &.is-visible {
        visibility: visible;
        transform: translate3d(0, 0, 0); }

    &__inner {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 110px 50px 30px;
        overflow-y: auto; }

    &-toggle {
        position: relative;
        cursor: pointer;
        display: none;
        margin-right: 30px;

        &__1,
        &__2,
        &__3 {
            width: 21px;
            height: 1px;
            background: $black;
            border-radius: 0;
            margin: 6px auto;
            transition: all .2s ease;
            backface-visibility: hidden; }

        &.is-active {

            .sidebar-toggle__1 {
                transform: rotate(45deg) translate(5px, 5px); }

            .sidebar-toggle__2 {
                opacity: 0; }

            .sidebar-toggle__3 {
                transform: rotate(-45deg) translate(5px, -5px); } } } }

// Main Menu
.main-menu {
    margin-bottom: 20px;

    &__list {
        padding-left: 0;
        list-style: none;
        margin: 0; }

    &__item {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0; }

        &.is-active {
            .main-menu__link {
                font-size: 24px; } } }

    &__link {
        font-size: 16px;
        text-decoration: none;

        &:hover {
            text-decoration: underline; } } }




// Bottom Menu
.bottom-menu {
    margin-top: auto;

    &__list {
        padding-left: 0;
        list-style: none;
        margin: 0; }

    &__item {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0; }

        &.is-active {
            .bottom-menu__link {
                font-size: 24px; } } }

    &__link {
        font-size: 16px;
        text-decoration: none;

        &:hover {
            text-decoration: underline; } } }



// Media --------------------------------------------------------

//Medium: ≥768px
@include media-breakpoint-down(md) {
    .sidebar-toggle {
        display: block; } }


//Extra small: >0
@include media-breakpoint-down(xs) {
    .sidebar-toggle {
        margin-right: 15px; } }
