.footer {
    background: $black;
    padding: 45px 0;

    &__col {
        display: flex;
        justify-content: space-between; }

    &__logo {
        margin-right: 30px;

        .main-logo__img {
            fill: #fff;
            width: 225px;
            height: auto; } }

    &-nav {
        margin: 0;

        &__list {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            flex-wrap: wrap;
            padding-left: 0;
            list-style: none;
            margin: 0; }

        &__item {
            margin-right: 35px;
            line-height: 1.8;

            &:last-child {
                margin-right: 0; } }

        &__link {
            font-size: 16px;
            color: #888;
            text-decoration: none;
            transition: all .5s ease;

            &:hover {
                color: $orange;
                text-decoration: none; } } }

    &__copyright {
        font-size: 16px;
        line-height: 1.8;
        color: #888;
        width: 225px;
        text-align: right;
        white-space: nowrap; } }




// Media ----------------------------------------------
@include media-breakpoint-down(md) {
    .footer__col {
        display: block; }

    .footer__logo {
        text-align: center;
        margin-bottom: 20px;

        .main-logo__link {
            justify-content: center; } }

    .footer-nav {
        margin-bottom: 20px; }

    .footer-nav__list {
        justify-content: center;
        flex-wrap: wrap; }

    .footer__copyright {
        width: 100%;
        text-align: center; } }

@include media-breakpoint-down(sm) {
    .footer-nav__item {
        margin-right: 15px; }

    .footer-nav__link {
        font-size: 14px; }

    .footer__copyright {
        font-size: 14px; } }
