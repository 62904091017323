.tokens-page {
    padding: 130px 0 60px;

    &__inner {
        max-width: 1230px;
        margin: 0 auto;
        padding: 0 15px; }

    &__header {
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-bottom: 20px; }

    &__title {
        font-size: 24px;
        margin: 0 10px 0 0; }

    &__rates {
        font-size: 13px; }

    &__buttons {
        margin-left: auto; }

    &__button {
        margin-right: 10px;
        height: 40px;
        font-size: 16px;

        &:last-child {
            margin-right: 0; } }

    &__table {

        &--tablet {
            display: none; }

        &--mobile {
            display: none; } } }


.tokens-about {
    background: #fafafa;
    padding: 35px 10px;
    margin-bottom: 40px;

    &__row {
        align-items: center; }

    &__picture {
        display: block;
        width: 148px; }

    &__img {
        max-width: 100%;
        height: auto; }

    &__title {
        font-size: 24px;
        margin: 0;
        text-transform: lowercase; }

    &__descr {
        font-size: 12px;

        p {
            &:last-child {
                margin-bottom: 0; } } }

    &__price {
        font-size: 24px;
        font-weight: 700;
        text-transform: lowercase;
        margin-bottom: 10px; }

    &__button {
        margin-bottom: 15px; }

    &__date {
        font-size: 12px; } }




// Media ------------------------------------------
@include media-breakpoint-down(lg);



@include media-breakpoint-down(md) {
    .tokens-about__col {
        margin-bottom: 20px; }

    .tokens-page__table--desktop {
        display: none; }

    .tokens-page__table--tablet {
        display: table; } }

@include media-breakpoint-down(xs) {
    .tokens-page {
        padding: 58px 0 60px; }

    .tokens-page__buttons {
        width: 100%;
        margin: 15px 0 0; }

    .tokens-page__button {
        width: 100%; }

    .tokens-page__title {
        font-size: 18px; }

    .tokens-page__rates {
        font-size: 12px; }

    .tokens-about__picture {
        text-align: center;
        margin: 0 auto; }

    .tokens-about__title {
        font-size: 18px;
        text-align: center; }

    .tokens-about__descr {
        text-align: center;
        font-size: 12px; }

    .tokens-about__buy {
        text-align: center; }

    .tokens-about {
        padding: 30px 15px 10px;
        margin-bottom: 10px; }

    .tokens-page__table--tablet {
        display: none; }

    .tokens-page__table--mobile {
        display: table;

        tbody {
            tr {
                td {
                    vertical-align: top;
                    padding: 12px 0; } } }

        .small-text {
            margin-bottom: 10px; } } }
