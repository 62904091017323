.popup-container {
    position: fixed;
    z-index: 1000;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .4);
    transition: all .5s ease;
    overflow-y: auto;
    overflow-x: hidden;
    opacity: 1;
    visibility: hidden;

    &__inner {
        min-height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 15px; }

    &.is-visible {
        opacity: 1;
        visibility: visible; } }


.scroll-disabled {
    overflow: hidden; }


.popup {
    position: relative;
    max-width: 500px;
    width: 100%;
    background: #fff;
    padding: 0;
    transition: all .5s ease;
    margin: 0 auto;
    display: none;
    opacity: 0;
    visibility: hidden;

    &.is-visible {
        display: block;
        opacity: 1;
        visibility: visible; }

    &__close {
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
        display: flex;
        opacity: .4;
        transition: all .5s ease;

        &:hover {
            opacity: 1; }

        &-icon {
            width: 14px;
            height: 14px;
            stroke: #000; } }

    &__body {
        padding: 40px 50px 50px;

        &--pt-60 {
            padding: 60px 50px; } }

    &__text {
        text-align: center;
        margin-bottom: 35px;

        h2 {
            margin-bottom: 20px; }

        p {
            font-size: 16px;
            margin-bottom: 1.2rem;

            &.p-small {
                font-size: 13px;
                margin: 0 0 5px; }

            &.p-dots {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis; }

            &:last-child {
                margin-bottom: 0; } }

        &--left {
            text-align: left; } }

    &__qr {

        &-picture {
            text-align: center;
            display: block; }

        &-img {
            max-width: 100%;
            height: auto; } } }




.info-popup {

    &__body {
        padding: 40px 50px; }

    &__info {
        display: flex;
        align-items: center;
        font-size: 13px;
        line-height: 1;
        margin-bottom: 15px;

        &-icon {
            width: 16px;
            height: 16px;
            fill: #56ce62;
            margin-right: 10px; } }

    &__text {

        h2 {
            margin-bottom: 20px; }

        p {
            font-size: 16px;
            margin-bottom: 1.2rem;

            &:last-child {
                margin-bottom: 0; } } }

    &__img {
        max-width: 100%;
        height: auto; } }



.system-popup {

    &__body {
        padding: 30px 20px 40px; }

    &__title {
        text-align: center;
        margin: 0 0 20px;
        font-size: 24px; }

    &__icon {
        width: 20px;
        height: 20px;
        margin-left: 5px;

        &--red {
            fill: $red; }

        &--green {
            fill: $green; } }

    &__buttons {
        text-align: center;
        max-width: 305px;
        margin: 0 auto;
        display: flex;
        justify-content: space-around; }

    &__button {
        max-width: 140px;
        width: 100%;
        font-size: 16px;
        height: 40px; } }



.popup-token {
    max-width: 700px;

    &__header {
        margin-bottom: 30px; }

    &__chart {
        &-img {
            width: 100%;
            height: auto; } }

    &__tabs-panes {
        padding-top: 40px; }

    &__tabs-toggle {
        margin-left: auto; }

    &__rates {

        &-title {
            font-size: 16px; }

        &-price {
            font-size: 24px; } }

    &__row {
        align-items: flex-end; }

    &__col {
        display: flex; } }




.auth-step {
    position: relative;
    padding-left: 50px;
    margin-bottom: 30px;

    &:last-child {
        margin-bottom: 0; }

    &__number {
        position: absolute;
        top: 7px;
        left: 0;
        width: 35px;
        height: 35px;
        border: 1px solid $black;
        color: $black;
        text-align: center;
        font-size: 18px;
        line-height: 32px; }

    &__descr {
        font-size: 16px;
        margin-bottom: 15px; }

    &__info {
        position: relative;
        padding-left: 25px;
        font-size: 13px;
        text-align: left;
        margin-left: -26px; }

    &__qr-img {
        margin-left: -14px;
        width: 150px;
        height: 150px; }

    &__buttons {
        display: flex;
        align-items: center;
        flex-wrap: wrap; }

    &__button {
        margin: 0 20px 10px 0;
        display: block;
        cursor: pointer; } }



.filter-popup {
    max-width: 330px;

    .fancybox-close-small {
        top: 7px; }

    &__header {
        background: #f5ebdb;
        padding: 20px; }

    &__title {
        font-size: 18px;
        line-height: 1.2;
        margin: 0; }

    &__body {
        padding: 0 20px 60px; }

    &__footer {
        display: flex; }

    &__button {
        width: 50%;
        height: 40px;
        font-size: 16px; }

    &__dropdown {

        .dropdown-button {
            height: 50px; }

        .dropdown-menu {
            position: relative;
            top: auto;
            left: auto;
            margin: 0;
            opacity: 1;
            visibility: visible;
            display: none;
            border: 0;
            border-bottom: 1px solid $black;
            box-shadow: none;
            padding-bottom: 10px;

            &.is-visible {
                display: block; } }

        .dropdown-menu__item {
            padding: 10px 15px; }

        &.is-active {
            .dropdown-button {
                border-color: transparent; } } } }

// sass-lint:disable class-name-format
.ReactModal__Body--open {
    .popup-container {
        opacity: 1;
        visibility: visible; } }

.ReactModal__Overlay {
    opacity: 0;
    transition: opacity .5s ease; }


.ReactModal__Overlay--after-open {
    opacity: 1; }

.ReactModal__Overlay--before-close {
    opacity: 0; }

// sass-lint:enable class-name-format



// Media -----------------------------------------------
@include media-breakpoint-down(xs) {
    .info-popup__body {
        padding: 20px 15px; }

    .info-popup__text h2 {
        font-size: 18px; }

    .info-popup__text p {
        font-size: 14px;
        margin-bottom: .9rem; }

    .popup__form {
        .form__button,
        .form__buttons {
            margin: 0; } }

    .popup__text {
        margin-bottom: 20px;

        h2 {
            font-size: 18px; }

        p {
            font-size: 14px;
            margin-bottom: .9rem; } }

    .popup__body {
        padding: 30px 15px 25px; }

    .system-popup__body {
        padding: 30px 20px; }

    .system-popup__title {
        font-size: 18px; } }



@include media-breakpoint-down(xs) {
    .popup-token__token {
        .token-item__unit {
            display: block;
            margin: 5px 0 0; } }

    .popup-token__header {
        margin-bottom: 20px; }

    .popup-token__rates {
        margin-bottom: 15px; }

    .popup-token__rates-title {
        font-size: 14px; }

    .popup-token__tabs-toggle {
        margin-left: 0;
        width: 100%;

        .tabs-toggle__item {
            width: 100%; } }

    .popup-token__rates-price {
        font-size: 18px; } }
