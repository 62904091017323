.buy-painting-page {
    padding: 130px 0 75px;

    &__inner {
        max-width: 1230px;
        margin: 0 auto;
        padding: 0 15px; }

    &__prev-step {
        text-align: left;
        margin-bottom: 25px; }

    &__title {
        font-size: 36px;
        margin: 0 0 60px;
        text-transform: uppercase;
        text-align: center; }

    &__cards {
        display: flex;
        justify-content: center;
        margin-bottom: 90px; }

    &__or {
        display: flex;
        align-items: center;
        margin: 0 65px;
        font-size: 18px;
        color: #888; }

    &__info {
        background: #fafafa;
        padding: 25px 15px;

        &-col {
            &:first-child {
                border-right: 1px solid $black; } } } }




.buy-painting {
    position: relative;
    display: flex;
    flex-direction: column;
    text-align: center;
    background: #fafafa;
    max-width: 425px;
    width: 100%;
    padding: 40px 15px 25px;

    &__title {
        font-size: 24px;
        font-weight: 700;
        line-height: 1.4;
        margin: 0 0 30px;
        text-transform: lowercase; }

    &__quantity {
        max-width: 220px;
        margin: 0 auto 20px; }

    &__price {
        font-size: 24px;
        line-height: 1.67;
        font-weight: 400;
        margin: 0 0 20px; }

    &__descr {
        line-height: 1.4;
        font-size: 12px;
        margin-bottom: 30px;

        p {
            color: #50555c;
            margin: 0 0 8px; }

        ul {
            padding-left: 0;
            list-style: none;
            margin: 0;

            li {
                color: #888; } } }

    &__button {
        margin-top: auto;
        max-width: 165px;
        margin: auto auto 30px;
        width: 100%; }

    &__info {
        font-size: 10px;
        color: #888;

        p {
            margin: 0; }

        a {
            font-weight: 700;
            color: #888; } } }




.about-painting {
    padding: 0 40px;

    &__title {
        font-size: 24px;
        font-weight: 700;
        line-height: 1.4;
        margin: 0 0 25px;
        text-transform: lowercase;

        &--mb-15 {
            margin-bottom: 15px; } }

    &__table {
        margin-bottom: 0;
        background-color: transparent;
        table-layout: fixed;

        tbody {
            tr {
                td {
                    vertical-align: top;
                    padding: 4px 0;
                    font-size: 16px;
                    text-transform: lowercase;

                    &:first-child {
                        width: 10%;
                        font-weight: 700;
                        white-space: nowrap;
                        padding-right: 30px; } } } } }

    &__history-table {
        tbody {
            tr {
                td {
                    padding: 15px 20px; } } } } }



// Media -------------------------------------
@include media-breakpoint-down(md) {
    .buy-painting-page__info-col {
        &:first-child {
            border: 0;
            margin-bottom: 50px; } } }


@include media-breakpoint-down(sm) {
    .buy-painting-page__or {
        margin: 0 30px; }

    .about-painting {
        padding: 0; } }


@include media-breakpoint-down(xs) {
    .buy-painting-page {
        padding: 58px 0 50px; }

    .buy-painting-page__title {
        font-size: 18px;
        margin-bottom: 20px; }

    .buy-painting-page__prev-step {
        margin-bottom: 12px; }

    .buy-painting-page__cards {
        display: block;
        margin-bottom: 50px; }

    .buy-painting {
        max-width: 100%; }

    .buy-painting__title {
        font-size: 20px; }

    .buy-painting-page__or {
        display: block;
        text-align: center;
        margin: 15px 0;
        font-size: 14px; }

    .about-painting__title {
        text-align: center;
        font-size: 20px; }

    .about-painting__history-table {
        thead {
            tr {
                th {
                    padding: 15px 0; } } }

        tbody {
            tr {
                td {
                    padding: 15px 5px 15px 0; } } } } }
