.portfolio-page {
    padding: 130px 0 0;

    &__inner {
        max-width: 1230px;
        margin: 0 auto;
        padding: 0 15px; }

    &__chart {

        &-img {
            width: 100%;
            height: auto;
            margin-bottom: 5px; } }

    &__tabs-toggle {
        margin-bottom: -30px; }

    &__tabs {
        margin-bottom: 40px; }

    &__table {

        &--tablet {
            display: none;

            tbody {
                tr {

                    &:nth-child(odd) {
                        td {
                            border-bottom: 0;
                            padding-bottom: 0; } } } } }

        &--mobile {
            display: none;

            tbody {
                tr {
                    td {
                        vertical-align: top;
                        text-align: center;
                        padding: 12px 0; }

                    &:first-child {
                        td {
                            border-top: 1px solid $black; } }

                    &:nth-child(3n+3) {
                        td {
                            border-color: $black; } } } }

            .small-text {
                margin-bottom: 10px; } } } }




// Portfolio Empty
.portfolio-empty {
    text-align: center;
    margin-top: 25vh;

    &__icon {
        width: 30px;
        height: 30px;
        fill: #56ce62;
        margin: 0 auto 20px; }

    &__text {
        text-align: center;
        font-size: 16px;
        max-width: 405px;
        margin: 0 auto 30px;

        p {
            &:last-child {
                margin-bottom: 0; } } }

    &__buttons {
        display: flex;
        align-items: center;
        justify-content: center; }

    &__button {
        margin-right: 10px;

        &:last-child {
            margin-right: 0; } } }



// Media --------------------------------------------

//Large: ≥992px
@include media-breakpoint-down(lg) {
    .portfolio-page {
        padding-left: 0; }

    .portfolio-page__inner {
        padding-right: 40px; } }

//Medium: ≥768px
@include media-breakpoint-down(md) {
    .portfolio-page__table--desktop {
        display: none; }

    .portfolio-page__table--tablet {
        display: table; } }


//Small: ≥576px
@include media-breakpoint-down(sm) {
    .portfolio-page__tabs {
        display: flex;
        flex-direction: column; }

    .portfolio-page__tabs-toggle {
        width: 100%;
        order: 2;
        margin: 0;

        .tabs-toggle__item {
            width: 100%; } }

    .portfolio-page__tabs-panes {
        margin-bottom: 20px; }

    .portfolio-empty__buttons {
        flex-direction: column; }

    .portfolio-empty__button {
        width: 100%;
        margin: 0 0 10px;

        &:last-child {
            margin: 0; } }

    .portfolio-empty__icon {
        margin-bottom: 10px; }

    .portfolio-empty__text {
        font-size: 14px;
        margin-bottom: 20px; } }


//Extra small: >0
@include media-breakpoint-down(xs) {
    .portfolio-page {
        padding: 58px 0 0; }

    .portfolio-page__inner {
        padding: 20px 15px; }

    .portfolio-page__header {
        flex-direction: column;
        align-items: flex-start; }

    .portfolio-page__buttons {
        margin-bottom: 20px;
        width: 100%;
        display: flex; }

    .portfolio-page__button {
        width: 100%;
        white-space: nowrap;
        font-size: 14px;
        padding: 5px 10px; }

    .portfolio-page__title {
        order: 1;
        font-size: 18px; }

    .portfolio-page__table--tablet {
        display: none; }

    .portfolio-page__table--mobile {
        display: table; }

    .portfolio-empty {
        margin-top: 20vh; } }
