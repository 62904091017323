.picture-section {
    padding: 125px 0 160px;

    &__title {
        margin-bottom: 105px; } }



.main-picture-card {
    height: 100%;
    background: #fafafa;

    &__picture {
        display: block; }

    &__img {
        width: 100%;
        height: auto; }

    &__descr {
        padding: 45px 40px 60px; }

    &__header {
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        margin-bottom: 50px;
        line-height: 1; }

    &__title {
        font-size: 36px;
        line-height: 1.2;
        text-transform: lowercase;
        font-weight: 700;
        margin: 0 30px 0 0; }

    &__text {
        font-size: 18px;
        line-height: 1.75;

        p {
            &:last-child {
                margin-bottom: 0; } } } }



// Media ----------------------------------------------
@include media-breakpoint-down(md) {
    .picture-section__col {
        margin-bottom: 30px; } }

@include media-breakpoint-down(sm) {
    .picture-section {
        padding: 45px 0 20px; }

    .picture-section__title {
        margin-bottom: 50px; }

    .main-picture-card__descr {
        padding: 20px 20px 35px; }

    .main-picture-card__title {
        font-size: 30px; }

    .main-picture-card__text {
        font-size: 16px; }

    .main-picture-card__header {
        margin-bottom: 20px; } }
